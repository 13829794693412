import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Button from "react-bootstrap/esm/Button";

import { AppleIcon } from "../../lib/Icons";

import styles from "../Downloads.module.css";

export default function MacOSSellbox({ onDownload }) {
  return (
    <section className={styles.sellBox}>
      <Row>
        <Col lg={7} className="order-2 order-lg-1">
          <AppleIcon />
          <h2>Download Mac App</h2>
          <p className={styles.boxAbstract}>
            Host or join an online meeting with video and screen sharing on the
            desktop app.
          </p>
          <p className={styles.lead + " fcc-blue"}>
            Minimal operating system requirements:
          </p>
          <ul className={styles.boxAbstract}>
            <li>Mac® OSX 10.10</li>
            <li>
              Bandwidth: 100Kb/s (HD Audio), 400Kb/s (screen sharing), 1.1Mb/s
              (video)
            </li>
            <li>Desktop: Chrome™ 57, Firefox® 78, Safari® 10.1</li>
          </ul>
          <p>
            <Button
              size="lg"
              onClick={() => onDownload("mac")}
              title="Download for macOS"
            >
              Download for macOS
            </Button>
          </p>
        </Col>
        <Col lg={5} className="order-1 order-lg-2">
          <img
            alt="Laptop with Screen Sharing App"
            className={styles.boxImage + " img-fluid d-block mx-auto"}
            src="/react-assets/images/downloads/Downloads_Sellbox.jpg"
          />
        </Col>
      </Row>
    </section>
  );
}
