export function postByFormSubmit(url, csrf, new_tab = false) {
  const form = document.createElement("form");

  form.setAttribute("method", "post");
  form.setAttribute("action", url);

  const field = document.createElement("input");

  field.setAttribute("type", "hidden");
  field.setAttribute("name", "authenticity_token");
  field.setAttribute("value", csrf);

  if (new_tab) {
    form.setAttribute("target", "_blank");
  }

  form.appendChild(field);

  document.getElementsByTagName("body")[0].appendChild(form);

  form.submit();
}

export function loadScript(src) {
  const existingScript = document.querySelector(`script[src="${src}"]`);
  if (existingScript) {
    console.log(`Script with src ${src} is already loaded.`);
    return;
  }

  const script = document.createElement("script");
  script.src = src;
  script.async = true;
  script.defer = true;

  script.onload = function () {
    console.log(`Script loaded: ${src}`);
  };

  script.onerror = function () {
    console.error(`Failed to load script: ${src}`);
  };

  document.body.appendChild(script);
}

function formatPrice(price, formatPeriod = "month") {
  if (formatPeriod === "minute") {
    return `$${price}/min/caller`;
  } else if (formatPeriod === "year") {
    return `$${price}/yr`;
  } else {
    return `$${price}/mo`;
  }
}

function formatRate(rate, formatPeriod = "month") {
  const price =
    formatPeriod !== "minute" ? rate.recurrent_fee : rate.price_per_unit;
  const formattedPrice = formatPrice(price, formatPeriod);

  return {
    id: rate.id,
    name: rate.name,
    formatted_price: formattedPrice,
    attributes: rate.attributes,
    fees_recurrent: rate.fees_recurrent,
  };
}

function groupRates(allRates) {
  let rates = {
    fcc_pro: {},
    one_number: {},
    pwyc: {},
    extra_storage: [],
  };

  const storageRateNames = new Set([
    "Extra Storage 10Gb",
    "Extra Storage 10Gb - $3/mo",
    "Extra Storage 20Gb",
    "Extra Storage 20Gb - $5/mo",
    "Extra Storage 40Gb",
    "Extra Storage 40Gb - $10/mo",
    "Extra Storage 80Gb",
    "Extra Storage 80Gb - $20/mo",
    "Extra Storage 160Gb - $40/mo",
    "Extra Storage 1Tb - $100/mo",
  ]);

  allRates.forEach((rate) => {
    if (rate.name === "Custom MOH - $2/mo" || rate.name === "Custom MOH") {
      rates["conference_moh"] = formatRate(rate);
    } else if (
      rate.name === "Custom Greeting - $2/mo" ||
      rate.name === "Custom Greeting"
    ) {
      rates["conference_greeting"] = formatRate(rate);
    } else if (rate.name === "2c/min") {
      rates["toll_free"] = formatRate(rate, "minute");
    } else if (rate.name === "FCC Pro - $8.21/mo 1mo trial") {
      rate.attributes.fcc_pro = "monthly";
      rates["fcc_pro"]["monthly"] = formatRate(rate);
    } else if (rate.name === "FCC Pro - $79.00/yr") {
      rate.attributes.fcc_pro = "annual";
      rates["fcc_pro"]["annual"] = formatRate(rate, "year");
    } else if (rate.name === "One Number - $3.95/mo") {
      rate.attributes.one_number = "monthly";
      rates["one_number"]["monthly"] = formatRate(rate);
    } else if (rate.name === "One Number - $39.00/yr") {
      rate.attributes.one_number = "annual";
      rates["one_number"]["annual"] = formatRate(rate, "year");
    } else if (storageRateNames.has(rate.name)) {
      rate.amount = Math.round(rate.attributes.max_storage_size / 1024);
      rates.extra_storage.push(rate);
    } else if (rate.attributes.pwyc) {
      rates.pwyc[rate.attributes.pwyc] = rate;
    }
  });

  rates.extra_storage.sort((a, b) => a.amount - b.amount);

  return rates;
}

export async function loadData() {
  const responses = await Promise.all([
    fetch("/v2/currencies"),
    fetch("/v2/countries"),
    fetch("/v2/allrates"),
  ]);

  const [currencies, countries, rates] = await Promise.all(
    responses.map((response) => response.json())
  );
  return {
    currencies: currencies.currencies,
    countries: countries.countries,
    languages: countries.languages,
    rates: groupRates(rates.allrates),
  };
}

export async function loadRates() {
  const response = await fetch("/v2/allrates");
  if (!response.ok) console.error("Failed to load rates");

  const rates = await response.json();

  return groupRates(rates.allrates);
}

export function getCookie(cname) {
  const name = cname + "=";
  const cookies = document.cookie.split("; ");

  for (const cookie of cookies) {
    if (cookie.startsWith(name)) {
      return decodeURIComponent(cookie.substring(name.length));
    }
  }

  return null;
}

export function setCookie(cname, cvalue, exdays) {
  const d = new Date();
  d.setTime(d.getTime() + exdays * 24 * 60 * 60 * 1000);
  let expires = "expires=" + d.toUTCString();
  document.cookie =
    cname + "=" + encodeURIComponent(cvalue) + ";" + expires + ";path=/";
}

export function deleteCookie(cname) {
  document.cookie = cname + "=; Path=/; Expires=Thu, 01 Jan 1970 00:00:01 GMT;";
}

export const EU_COUNTRIES = [
  "at",
  "be",
  "bg",
  "hr",
  "cy",
  "cz",
  "dk",
  "ee",
  "fi",
  "fr",
  "de",
  "gr",
  "hu",
  "ie",
  "et",
  "lv",
  "lt",
  "lu",
  "mt",
  "nl",
  "pl",
  "pt",
  "ro",
  "sk",
  "si",
  "es",
  "se",
];

export function isEUCountry(countryCode) {
  return EU_COUNTRIES.includes(countryCode);
}

export function generateRandomId(length = 8) {
  return Math.random()
    .toString(36)
    .slice(2, 2 + length);
}

export function createDownloadIFrame(downloadUrl) {
  deleteCookie("launcher_token");

  // Wait 1.5 seconds (if needed)
  // await new Promise((resolve) => setTimeout(resolve, 1500));

  const iframe = document.createElement("iframe");
  iframe.id = "autoDownloadFrame";
  iframe.src = downloadUrl;
  iframe.style.display = "none";
  document.body.appendChild(iframe);
}

export function getOS() {
  const userAgent = window.navigator.userAgent.toLowerCase();
  console.log("userAgent=", userAgent);
  if (userAgent.includes("android")) return "Android";
  if (userAgent.includes("iphone") || userAgent.includes("ipad")) return "iOS";
  if (userAgent.includes("win")) return "Windows";
  if (userAgent.includes("mac")) return "macOS";
  if (userAgent.includes("linux")) return "Linux";

  return "Unknown OS";
}
