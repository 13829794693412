import { useState, useContext, createContext, useEffect } from "react";
import { isEUCountry } from "./helpers";

const SessionContext = createContext();

export default function SessionProvider({ children }) {
  const [session, setSession] = useState({
    isLoggedIn: null,
    subscription: null,
    csrfToken: null,
    countryCode: null,
    rates: null,
    showTerms: false,
    acceptedTerms: false,
  });

  const updateSession = (newSession) => {
    setSession((prevSession) => ({
      ...prevSession,
      ...newSession,
    }));
  };

  useEffect(() => {
    const loadSession = async () => {
      try {
        const responses = await Promise.all([
          fetch("/v2/subscriptions/current?id=current"),
          fetch("/v2/country_code"),
          fetch("/csrf", {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
          }),
        ]);
        let subscription = null;
        if (responses[0].status === 200) {
          const json = await responses[0].json();
          subscription = json.subscription;
        }
        let countryCode = "us";
        if (responses[1].status === 200) {
          const json = await responses[1].json();
          countryCode = json.country_code;
        }
        let csrfToken = null;
        if (responses[2].status === 200) {
          const json = await responses[2].json();
          csrfToken = json.csrfToken;
        }

        const isLoggedIn = subscription !== null;
        updateSession({
          isLoggedIn: isLoggedIn,
          subscription: subscription,
          csrfToken: csrfToken,
          countryCode: countryCode,
          showTerms: isEUCountry(countryCode),
        });
      } catch (error) {
        console.error(error.message);
      }
    };

    loadSession();
  }, []);

  if (session.isLoggedIn === null) return null;

  return (
    <SessionContext.Provider value={{ session, updateSession }}>
      {children}
    </SessionContext.Provider>
  );
}

export const useSession = () => useContext(SessionContext);
