import Button from "react-bootstrap/Button";
import Container from "react-bootstrap/Container";

import { Helmet } from "react-helmet-async";

import { useModal } from "../lib/ModalProvider";

import styles from "./Aboutus.module.css";

export default function AboutusPage() {
  const { openModal } = useModal();

  function openSignupModal() {
    const modelOptions = {
      siteTag: "FCC_ABOUTUS",
    };
    openModal("signup", modelOptions);
  }

  return (
    <div className={styles.aboutusPage}>
      <Helmet>
        <title>About Us | FreeConferenceCall.com</title>
        <meta
          name="description"
          content="Download FreeConferenceCall.com apps for any device. Get video, screen sharing and conference calling on Mac and Windows and mobile devices."
        />
        <meta name="robots" content="index, follow" />
        <link
          rel="canonical"
          href="https://www.freeconferencecall.com/aboutus"
        />
        <meta property="og:title" content="About Us | FreeConferenceCall.com" />
        <meta property="og:type" content="company" />
        <meta
          property="og:url"
          content="https://www.freeconferencecall.com/aboutus"
        />
        <meta
          property="og:image"
          content="https://www.freeconferencecall.com/images/fcc_logo_fb.png"
        />
        <meta iproperty="og:site_name" content="FreeConferenceCall.com" />
        <meta property="fb:admins" content="freeconferencecall" />
      </Helmet>
      <section className={styles.header}>
        <Container>
          <div className="row d-flex align-items-stretch">
            <div className="col-lg-7 order-2 order-lg-1 d-flex flex-column justify-content-center p-3">
              <div className={styles.info}>
                <h1>Keeping it Free and Serving our Community for 25 Years</h1>
                <div className="row">
                  <div className="col-lg-7">
                    <Button
                      variant="orange"
                      className="w-100"
                      size="lg"
                      title="Sign Up Now"
                      onClick={openSignupModal}
                    >
                      Sign Up Now
                    </Button>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-5 order-1 order-lg-2 d-flex justify-content-center p-3">
              <img
                alt="25 Years Anniversary"
                className="img-fluid mx-auto d-block"
                src="/react-assets/images/aboutus/25_Years_Anniversary.webp"
              />
            </div>
          </div>
        </Container>
      </section>
      <section className={styles.aboutus}>
        <Container>
          <div className="row d-flex align-items-stretch">
            <div className="col-lg-6 order-1 order-lg-2 d-flex justify-content-center p-3">
              <img
                alt="About Us"
                className="img-fluid mx-auto d-block"
                src="/react-assets/images/aboutus/About_Us.webp"
              />
            </div>
            <div className="col-lg-6 order-2 order-lg-1 d-flex flex-column justify-content-center p-3">
              <div className="text">
                <h2>About Us</h2>
                <p>
                  At FreeConferenceCall.com, we believe that communication
                  should be seamless, accessible, and free. Since our inception,
                  we have been dedicated to providing reliable conferencing
                  solutions that empower individuals and organizations to
                  connect without barriers. FreeConferenceCall.com was founded
                  by David Erickson with the goal of providing FREE Conferencing
                  services to the world. Based in Long Beach, California we have
                  been keeping it free for 25 years!
                </p>
              </div>
            </div>
          </div>
        </Container>
      </section>
      <section className={styles.ourMission}>
        <Container>
          <div className="row d-flex align-items-stretch">
            <div className="col-lg-6 d-flex flex-column justify-content-center p-3">
              <img
                alt="Our Mission"
                className="img-fluid mx-auto d-block"
                src="/react-assets/images/aboutus/Our_Mission.webp"
              />
            </div>
            <div className="col-lg-6 d-flex flex-column justify-content-center p-3">
              <div className="text">
                <h2>Our Mission</h2>
                <p>
                  We strive to break down the obstacles of distance and cost,
                  enabling everyone—from small businesses to large enterprises—
                  engage in meaningful conversations, collaborate effectively,
                  and foster strong relationships, no matter where they are in
                  the world.
                </p>
              </div>
            </div>
          </div>
        </Container>
      </section>
      <section className={styles.whatWeOffer}>
        <Container>
          <div className="row d-flex align-items-stretch">
            <div className="col-lg-6 order-2 order-lg-1 d-flex flex-column justify-content-center p-3">
              <div className="text">
                <h2>What We Offer</h2>
                <p>
                  With our user-friendly platform, you can host high-quality
                  conference calls, video meetings, and webinars at no charge.
                  Our services include:
                </p>
                <ul>
                  <li>
                    Unlimited Conference Calls: Connect with up to 1,000
                    participants at once.
                  </li>
                  <li>
                    HD Video Conferencing: Enhance your meetings with
                    high-definition video capabilities.
                  </li>
                  <li>
                    Screen Sharing: Collaborate in real-time by sharing your
                    screen with participants.
                  </li>
                  <li>
                    Recording Features: Capture your calls for future reference
                    or sharing with absent members.
                  </li>
                </ul>
              </div>
            </div>
            <div className="col-lg-6 order-1 order-lg-2 d-flex justify-content-center p-3">
              <img
                alt="What We Offer"
                className="img-fluid mx-auto d-block"
                src="/react-assets/images/aboutus/What_We_Offer.webp"
              />
            </div>
          </div>
        </Container>
      </section>
      <section className={styles.ourCommunity}>
        <Container>
          <div className="row d-flex align-items-stretch">
            <div className="col-lg-6 d-flex flex-column justify-content-center p-3">
              <img
                alt="Our Community"
                className="img-responsive center-block"
                src="/react-assets/images/aboutus/Our_Community.webp"
              />
            </div>
            <div className="col-lg-6 d-flex flex-column justify-content-center p-3">
              <div className="text">
                <h2>Our Community</h2>
                <p>
                  We serve a diverse global community, from educators and
                  nonprofits to entrepreneurs and corporate teams. Our
                  commitment to inclusivity ensures that everyone can access our
                  tools, regardless of their location or budget.
                </p>
              </div>
            </div>
          </div>
        </Container>
      </section>
      <section className={styles.ourTeam}>
        <Container>
          <h2>Our Team</h2>
          <h3>
            Our passionate team of professionals is dedicated to continuous
            improvement and innovation. We listen to our users and strive to
            provide the best possible experience with our services.
          </h3>
          <div className="row">
            <div className="col-lg-4">
              <div className={styles.featureBox}>
                <img
                  alt="Dave Erickson"
                  src="/react-assets/images/aboutus/Dave_Erickson.webp"
                />
                <h3>Dave Erickson</h3>
                <h4>Founder</h4>
              </div>
            </div>
            <div className="col-lg-4">
              <div className={styles.featureBox}>
                <img
                  alt="Eugene Tcipnjatov"
                  src="/react-assets/images/aboutus/Eugene_Tcipnjatov.webp"
                />
                <h3>Eugene Tcipnjatov</h3>
                <h4>Co-Founder</h4>
              </div>
            </div>
            <div className="col-lg-4">
              <div className={styles.featureBox}>
                <img
                  alt="Andrew Nickerson"
                  src="/react-assets/images/aboutus/Andrew_Nickerson.webp"
                />
                <h3>Andrew Nickerson</h3>
                <h4>Chief Executive Officer</h4>
              </div>
            </div>
            <div className="col-lg-4">
              <div className={styles.featureBox}>
                <img
                  alt="Michael Ching"
                  src="/react-assets/images/aboutus/Michael_Ching.webp"
                />
                <h3>Michael Ching</h3>
                <h4>Chief Technology Officer</h4>
              </div>
            </div>
            <div className="col-lg-4">
              <div className={styles.featureBox}>
                <img
                  alt="Bryan Peterson"
                  src="/react-assets/images/aboutus/Bryan_Peterson.webp"
                />
                <h3>Bryan Peterson</h3>
                <h4>Chief Network Officer</h4>
              </div>
            </div>
            <div className="col-lg-4">
              <div className={styles.featureBox}>
                <img
                  alt="Joe Trenkamp"
                  src="/react-assets/images/aboutus/Joe_Trenkamp.webp"
                />
                <h3>Joe Trenkamp</h3>
                <h4>Chief Financial Officer</h4>
              </div>
            </div>
          </div>
        </Container>
      </section>
      <section className={styles.joinUs}>
        <Container>
          <h2 className="text-center">Join Us</h2>
          <h3>
            Discover the power of free communication. Join millions of users
            around the world who rely on FreeConferenceCall.com to connect,
            collaborate, and create.
          </h3>
          <div className="row">
            <div className="col-lg-4 offset-lg-4">
              <Button
                variant="orange"
                className="w-100"
                size="lg"
                title="Sign Up Now"
                onClick={openSignupModal}
              >
                Sign Up Now
              </Button>
            </div>
          </div>
        </Container>
      </section>
      <section className={styles.awards}>
        <Container>
          <h2>Awards & Recognition</h2>
          <h3>
            As a top-rated performer for innovative technology, usability and
            customer service, FreeConferenceCall.com is proud to be recognized
            for its successful efforts.
          </h3>
          <img
            alt="2018 CEO World Awards - Best 2018 Gold"
            src="/react-assets/images/aboutus/ceo-world-award-best2018.jpg"
          />
          <img
            alt="2016 Gold Stevie Winner - For Sales & Customer Service"
            src="/react-assets/images/aboutus/2016-gold-stevie-winner.png"
          />
          <img
            alt="Stevie Award"
            src="/react-assets/images/aboutus/award-stevie16.png"
          />
          <img
            alt="High Performer Award"
            src="/react-assets/images/aboutus/award-highperformer16.png"
          />
          <img
            alt="Highest RATED Award"
            src="/react-assets/images/aboutus/award-highestrated15.png"
          />
          <img
            alt="CRN Channel Chiefs Award"
            src="/react-assets/images/aboutus/award-cc16.png"
          />
        </Container>
      </section>
      <section className={styles.startingNow}>
        <Container>
          <h2>
            Host a Free Conference Call or Video Conference, Starting Now!
          </h2>
          <h3>
            Create your FreeConferenceCall.com account and get access to
            everything you need for your business or organization to hit the
            ground running, like video and screen sharing, meeting invitations,
            virtual meeting rooms and more.
          </h3>
          <div className="row">
            <div className="col-lg-4 offset-lg-4">
              <Button
                variant="orange"
                className="w-100"
                size="lg"
                title="Sign Up Now"
                onClick={openSignupModal}
              >
                Sign Up Now
              </Button>
            </div>
          </div>
        </Container>
      </section>
    </div>
  );
}
