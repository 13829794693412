import { useState } from "react";

import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";

import { Helmet } from "react-helmet-async";

import { useModal } from "../lib/ModalProvider";
import { useSession } from "../lib/SessionProvider";

import TermsCheckbox from "../components/TermsCheckbox.js";

import { postByFormSubmit } from "../lib/helpers";

import styles from "./Home.module.css";

export default function HomePage() {
  const [validated, setValidated] = useState(false);
  const [acceptedTerms, setAcceptedTerms] = useState(false);

  const { openModal } = useModal();
  const { session } = useSession();

  const siteTag = "FCC_HO_1_H";
  console.log("session=", session);

  const handleAcceptChange = (accepted) => {
    setAcceptedTerms(accepted);
  };

  function openSignupModal() {
    openModal("signup", { siteTag: siteTag });
  }

  function handleSignup(event) {
    const form = event.currentTarget;

    // Prevent from submission
    event.preventDefault();
    event.stopPropagation();

    if (form.checkValidity() === true) {
      const email = encodeURIComponent(form.email.value);
      const password = form.password.value;

      let signupURL = `/signup?email=${email}&password=${password}&auto_action=signup&site_tag=${siteTag}`;
      window.location.assign(signupURL);
    }

    setValidated(true);
  }

  function handleAppleLogin() {
    let signupURL = `/auth/apple?site_tag=${siteTag}`;
    postByFormSubmit(signupURL, session.csrfToken);
  }

  function handleGoogleLogin() {
    let signupURL = `/auth/google_oauth2?site_tag=${siteTag}`;
    postByFormSubmit(signupURL, session.csrfToken);
  }

  function handleLogin() {
    let loginURL = `/login?site_tag=${siteTag}`;
    window.location.assign(loginURL);
  }

  return (
    <div className={styles.homePage}>
      <Helmet>
        <title>Free Conference Call Services | FreeConferenceCall.com</title>
        <meta
          name="description"
          content="Join millions of users on the network that gives back to its community. Sign up for FreeConferenceCall.com at a cost that works for you."
        />
        <meta name="robots" content="index, follow" />
        <link rel="canonical" href="https://www.freeconferencecall.com" />
        <meta
          property="og:title"
          content="Add Value and Save with Paid Voice and Video Meetings Features | FreeConferenceCall.com"
        />
        <meta property="og:type" content="company" />
        <meta property="og:url" content="https://www.freeconferencecall.com" />
        <meta
          property="og:image"
          content="https://www.freeconferencecall.com/images/fcc_logo_fb.png"
        />
        <meta iproperty="og:site_name" content="FreeConferenceCall.com" />
        <meta property="fb:admins" content="freeconferencecall" />
      </Helmet>
      <section className={styles.header}>
        <Container className={styles.container}>
          <div className={styles["bottom-orange-circle"]}></div>
          <Row className="d-flex align-items-stretch">
            <Col
              lg={5}
              className="order-2 order-lg-1 d-flex flex-column justify-content-center"
            >
              <Form noValidate validated={validated} onSubmit={handleSignup}>
                <div className={styles.loginBox}>
                  <h2>Create your account</h2>

                  <Form.Group className="mb-4" controlId="formBasicEmail">
                    <Form.Control
                      type="email"
                      size="lg"
                      name="email"
                      placeholder="Enter Email"
                      required
                    />
                    <Form.Control.Feedback className="text-left" type="invalid">
                      Please choose an email.
                    </Form.Control.Feedback>
                  </Form.Group>
                  <Form.Group className="mb-4" controlId="formBasicPassword">
                    <Form.Control
                      type="password"
                      size="lg"
                      name="password"
                      placeholder="Create a Password"
                      required
                    />
                    <Form.Control.Feedback className="text-left" type="invalid">
                      Please choose a password.
                    </Form.Control.Feedback>
                  </Form.Group>
                  <TermsCheckbox
                    className="mb-4 text-left"
                    onAcceptChange={handleAcceptChange}
                    countryCode={session.countryCode}
                  />
                  <Button
                    variant="orange"
                    className="w-100"
                    size="lg"
                    type="submit"
                    disabled={!acceptedTerms}
                    title="Create My Free Account"
                  >
                    Create My Free Account
                  </Button>

                  <div className="row mt-4">
                    <div className="col-2 col-md-3">
                      <hr style={{ color: "#aaa" }} />
                    </div>
                    <div className="col-8 col-md-6 text-center small">
                      <p className="text-center mt-1" style={{ color: "#555" }}>
                        or continue with
                      </p>
                    </div>
                    <div className="col-2 col-md-3">
                      <hr style={{ color: "#aaa" }} />
                    </div>
                  </div>

                  <div className="d-flex align-items-center justify-content-center">
                    <Button
                      onClick={handleAppleLogin}
                      variant="white"
                      title="Apple"
                      disabled={!acceptedTerms}
                      className="d-flex flex-column align-items-center justify-content-center p-3 m-1"
                    >
                      <div className={styles.svg_wrapper}>
                        <img
                          src="/react-assets/images/icons/Apple-Icon.svg"
                          alt="Apple Icon"
                        />
                      </div>
                      <span>Apple</span>
                    </Button>
                    <Button
                      onClick={handleGoogleLogin}
                      variant="white"
                      title="Google"
                      disabled={!acceptedTerms}
                      className="d-flex flex-column align-items-center justify-content-center p-3 m-1"
                    >
                      <div className={styles.svg_wrapper}>
                        <img
                          src="/react-assets/images/icons/Google-Icon.svg"
                          alt="Google Icon"
                        />
                      </div>
                      <span>Google</span>
                    </Button>
                  </div>

                  <div className="text-center">
                    By signing up, I agree to the{" "}
                    <a href="/terms-of-service">Terms of Service</a>
                    <br />
                    <Button
                      variant="link"
                      onClick={handleLogin}
                      disabled={!acceptedTerms}
                    >
                      Have an account? Log in
                    </Button>
                  </div>
                </div>
              </Form>
            </Col>
            <Col
              lg={6}
              className="offset-lg-1 order-1 order-lg-2 d-flex flex-column justify-content-center"
            >
              <div className={styles.info}>
                <h1 className="text-left">
                  Free, High-Quality Conference Call Services
                </h1>
                <p className={styles.lead}>
                  Connect and collaborate on a meeting platform that gives you{" "}
                  <strong className={styles.orange}>UNLIMITED ACCESS</strong> to
                  conference calling and video calling.
                </p>
              </div>
            </Col>
          </Row>
        </Container>
      </section>
      <section className={styles.subHeader}>
        <Container className={styles.container}>
          <div className={styles["top-orange-circle"]}></div>
          <div className="row d-flex align-items-stretch">
            <div className="col-lg-7 d-flex flex-column justify-content-center">
              <div className={styles.left}>
                <h2 className="text-left">A Free Conferencing Tool for Life</h2>
                <p>
                  See why 98% of our users surveyed say they would recommend our
                  conferencing services to a friend or colleague. Our most
                  popular features fit your needs — whether it’s for your
                  business or simply keeping in touch. Instantly connect with up
                  to 1,000 participants. All made possible by our
                  reservationless conference calling service that has supported
                  billions of calls and meetings for 25 years.
                </p>
                <div className="row">
                  <div className="col-lg-6">
                    <Button
                      variant="green"
                      onClick={openSignupModal}
                      size="lg"
                      title="Join Now"
                      className="w-100"
                    >
                      Join Now
                    </Button>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-5">
              <div className="row">
                <div className="col-6">
                  <div className={styles.featureBox}>
                    <img
                      className="img-fluid d-block mx-auto"
                      alt="Phone"
                      src="/react-assets/images/homepage/Phone.png"
                    />
                    <strong>Audio Conferencing</strong>
                  </div>
                </div>

                <div className="col-6">
                  <div className={styles.featureBox}>
                    <img
                      className="img-fluid d-block mx-auto"
                      alt="Video-On"
                      src="/react-assets/images/homepage/Video-On.png"
                    />
                    <strong>Video Conferencing</strong>
                  </div>
                </div>

                <div className="col-6">
                  <div className={styles.featureBox}>
                    <img
                      className="img-fluid d-block mx-auto"
                      alt="Monitor"
                      src="/react-assets/images/homepage/Monitor.png"
                    />
                    <strong>Screen Sharing</strong>
                  </div>
                </div>

                <div className="col-6">
                  <div className={styles.featureBox}>
                    <img
                      className="img-fluid d-block mx-auto"
                      alt="Record"
                      src="/react-assets/images/homepage/Record.png"
                    />
                    <strong>Recordings</strong>
                  </div>
                </div>

                <div className="col-6">
                  <div className={styles.featureBox}>
                    <img
                      className="img-fluid d-block mx-auto"
                      alt="Globe"
                      src="/react-assets/images/homepage/Globe.png"
                    />
                    <strong>Global Access</strong>
                  </div>
                </div>

                <div className="col-6">
                  <div className={styles.featureBox}>
                    <img
                      className="img-fluid d-block mx-auto"
                      alt="Krisp"
                      src="/react-assets/images/homepage/Krisp.png"
                    />
                    <strong>Noise Cancellation</strong>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Container>
      </section>
      <section className={styles.videoConferencing}>
        <Container className={styles.container}>
          <div className="row">
            <div className="col-lg-6">
              <div className={styles.left}>
                <h2>Free Video Conferencing and Free Screen Sharing</h2>
                <p>
                  Each account includes free online meetings packed with
                  intuitive features.
                </p>
                <div className="row">
                  <div className="col-lg-8">
                    <Button
                      variant="orange"
                      href="/downloads"
                      className="w-100"
                      size="lg"
                      title="Download Desktop App"
                    >
                      Download Desktop App
                    </Button>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-6">
              <div className={styles.right}>
                <div className={styles.featureBox}>
                  <img
                    src="/react-assets/images/homepage/Group.png"
                    alt="Group"
                  />
                  <span className={styles.featureText}>
                    Host unlimited video participants.
                  </span>
                </div>

                <div className={styles.featureBox}>
                  <img
                    src="/react-assets/images/homepage/Wrench.png"
                    alt="Wrench"
                  />
                  <span className={styles.featureText}>
                    Access switch presenter, remote control and drawing tools.
                  </span>
                </div>

                <div className={styles.featureBox}>
                  <img src="/react-assets/images/homepage/Rec.png" alt="Rec" />
                  <span className={styles.featureText}>
                    Record audio-visual presentations for live broadcast.
                  </span>
                </div>

                <div className={styles.featureBox}>
                  <img
                    src="/react-assets/images/homepage/Comment.png"
                    alt="Comment"
                  />
                  <span className={styles.featureText}>
                    Chat privately or with all participants.
                  </span>
                </div>
              </div>
            </div>
          </div>
        </Container>
      </section>
      <section className={styles.conferencingApps}>
        <Container className={styles.container}>
          <div className="row d-flex align-items-stretch">
            <div className="col-lg-6 d-flex flex-column justify-content-center">
              <div className={styles.left}>
                <img
                  alt="Mobile Phone People"
                  className="img-fluid mx-auto d-block"
                  src="/react-assets/images/homepage/Mobile-Phone-People.webp"
                />
              </div>
            </div>
            <div className="col-lg-6 d-flex flex-column justify-content-center">
              <div className={styles.right}>
                <h2>We Go Where You Go with Conferencing Apps</h2>
                <p>
                  FreeConferenceCall.com blends seamlessly into your life.
                  Download the mobile app and get our free phone conferencing
                  software. Collaboration is just a tap away.
                </p>
                <div className={styles["app-store"]}>
                  <a
                    className={styles["app-store-btn"]}
                    href="https://itunes.apple.com/us/app/free-conference-call/id1076834814?mt=8"
                    target="_blank"
                    rel="noreferrer"
                    title="Download the FreeConferenceCall.com mobile app for iOS on the App Store"
                  >
                    <img
                      alt="Apple App Store Logo"
                      src="/react-assets/images/homepage/Apple_App_Store.png"
                    />
                  </a>
                  <a
                    className={styles["app-store-btn"]}
                    href="https://play.google.com/store/apps/details?id=com.freeconferencecall.fccmeetingclient"
                    target="_blank"
                    rel="noreferrer"
                    title="Download the FreeConferenceCall.com mobile app for Android on Google Play"
                  >
                    <img
                      alt="Google Play Store Logo"
                      src="/react-assets/images/homepage/Google_Play_App.png"
                    />
                  </a>
                </div>
              </div>
            </div>
          </div>
        </Container>
      </section>
      <section className={styles.internationalConferencing}>
        <div className={styles.background}></div>
        <Container className={styles.container}>
          <div className="row">
            <div className="col-lg-8">
              <h2>Free International Conference Calling</h2>
              <h3>More than 2 Billion Calls Served Worldwide</h3>
              <p>
                Yes, that's a lot of calls. But we've built the network to
                handle being a global conferencing leader. We take pride in
                providing high-quality, reliable, and easy-to-use conferencing
                services, backed by our award-winning Customer Care Team.
              </p>
              <div className="row">
                <div className="col-lg-6">
                  <Button
                    variant="primary"
                    className="w-100"
                    size="lg"
                    onClick={openSignupModal}
                    title="Start Calling Now"
                  >
                    Start Calling Now
                  </Button>
                </div>
              </div>
            </div>
          </div>
        </Container>
      </section>
      <section className={styles.conferenceCallFeatures}>
        <Container className={styles.container}>
          <h2>Everything You Ever Wanted in a Conference Call</h2>
          <p>
            It’s hard to believe you get so many features with a free conference
            call service. We’re committed to making global communication and
            collaboration as easy and accessible as possible. With
            FreeConferenceCall.com, you’re in control of how you connect.
          </p>
          <div className={styles.features}>
            <div className="row">
              <div className="col-lg-3">
                <div className={styles.featureBox}>
                  <img
                    src="/react-assets/images/homepage/Record-blue.png"
                    alt="Recordings"
                  />
                  <span className={styles.featureText}>
                    History & Recordings
                  </span>
                </div>
              </div>
              <div className="col-lg-3">
                <div className={styles.featureBox}>
                  <img
                    src="/react-assets/images/homepage/File-blue.png"
                    alt="File"
                  />
                  <span className={styles.featureText}>
                    Call Details Report
                  </span>
                </div>
              </div>
              <div className="col-lg-3">
                <div className={styles.featureBox}>
                  <img
                    src="/react-assets/images/homepage/Card-blue.png"
                    alt="Card"
                  />
                  <span className={styles.featureText}>
                    Custom Meeting Wall
                  </span>
                </div>
              </div>
              <div className="col-lg-3">
                <div className={styles.featureBox}>
                  <img
                    src="/react-assets/images/homepage/Upgrades-blue.png"
                    alt="Upgrades"
                  />
                  <span className={styles.featureText}>Upgrades</span>
                </div>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-lg-4 offset-lg-4">
              <Button
                href="/features"
                className="w-100"
                size="lg"
                title="View All Features"
              >
                View All Features
              </Button>
            </div>
          </div>
        </Container>
      </section>
      <section className={styles.featuredIn}>
        <Container className={styles.container}>
          <h2>Featured In</h2>
          <div className={styles.features + " row"}>
            <div className="col-lg-3">
              <img
                className="img-fluid"
                src="/images/application_wide/index/2018-design/inc-logo-white.png"
                alt="Inc"
              />
            </div>
            <div className="col-lg-3">
              <img
                className="img-fluid"
                src="/images/application_wide/index/2018-design/usatoday-logo-white.png"
                alt="USA Today"
              />
            </div>
            <div className="col-lg-3">
              <img
                className="img-fluid"
                src="/images/application_wide/index/2018-design/fox-business-logo-white.png"
                alt="Fox Business"
              />
            </div>
            <div className="col-lg-3">
              <img
                className="img-fluid"
                src="/images/application_wide/index/2018-design/entreneur-logo-white.png"
                alt="Entrepreneur Magazine"
              />
            </div>
          </div>
          <div className="row">
            <div className="col-lg-4 offset-lg-4">
              <Button
                variant="white"
                href="/press-center"
                className="w-100"
                size="lg"
                title="View Press Center"
              >
                View Press Center
              </Button>
            </div>
          </div>
        </Container>
      </section>
      <section className={styles.howFree}>
        <Container className={styles.container}>
          <h2>How Will You Do Free?</h2>
          <p>
            Discover how our diverse users utilize our innovative conferencing
            solution and achieve the same powerful results.
          </p>
          <div className={styles.features + " row"}>
            <div className="col-lg-6">
              <div className={styles.featureBox}>
                <img
                  src="/react-assets/images/homepage/Run-Bussiness.png"
                  alt="Run your own business"
                />
                <h3>Run your own business</h3>
                <span className={styles.featureText}>
                  Entrepreneurs and consultants have trusted
                  FreeConferenceCall.com for its reliable, professional
                  conferencing. Free doesn’t mean compromising on quality—our
                  service delivers business-caliber conferencing to help you
                  drive your business forward with online meetings.
                </span>
              </div>
            </div>
            <div className="col-lg-6">
              <div className={styles.featureBox}>
                <img
                  src="/react-assets/images/homepage/Work-Remotely.png"
                  alt="Work remotely"
                />
                <h3>Work remotely</h3>
                <span className={styles.featureText}>
                  Whether your remote team is spread across states or
                  continents, you can connect everyone using
                  FreeConferenceCall.com. Share information quickly and reliably
                  with the collaboration tools you need. It’s the perfect
                  meeting software for nonprofits on a budget.
                </span>
              </div>
            </div>
            <div className="col-lg-6">
              <div className={styles.featureBox}>
                <img
                  src="/react-assets/images/homepage/Connect-Classroom.png"
                  alt="Connect the classroom"
                />
                <h3>Connect the classroom</h3>
                <span className={styles.featureText}>
                  Educators rely on our services to host virtual classrooms.
                  Both teachers and students can create compelling audio-visual
                  presentations and participate in dynamic breakout sessions for
                  effective group study.
                </span>
              </div>
            </div>
            <div className="col-lg-6">
              <div className={styles.featureBox}>
                <img
                  src="/react-assets/images/homepage/Run-Bussiness.png"
                  alt="Build your faith community"
                />
                <h3>Build your faith community</h3>
                <span className={styles.featureText}>
                  Use FreeConferenceCall.com to offer weekly bible studies,
                  introduce services for new members, or create a free prayer
                  line. Our conferencing apps help people stay connected in
                  their faith communities.
                </span>
              </div>
            </div>
          </div>
        </Container>
      </section>
      <section className={styles.freeConferencing}>
        <Container className={styles.container}>
          <div className="row d-flex align-items-stretch">
            <div className="col-lg-6 order-2 order-lg-1 d-flex flex-column justify-content-center">
              <h2>100% Free Conference Call Service. Really.</h2>
              <p>
                FreeConferenceCall.com offers an award-winning conferencing
                solution that provides everything you need—from international
                phone conferencing to free video calls and screen sharing. All
                of this is available at no cost to you.
              </p>
              <p>Your meetings are on us, forever.</p>
              <div className="row">
                <div className="col-lg-7">
                  <Button
                    variant="orange"
                    onClick={openSignupModal}
                    className="w-100"
                    size="lg"
                    title="Create My Free Account"
                  >
                    Create My Free Account
                  </Button>
                </div>
              </div>
            </div>
            <div className="col-lg-6 order-1 order-lg-2 d-flex flex-column justify-content-center">
              <div className={styles.left}>
                <img
                  alt="Conference Call"
                  className="img-fluid d-block mx-auto"
                  src="/react-assets/images/homepage/Conference-Call.webp"
                />
              </div>
            </div>
          </div>
        </Container>
      </section>
    </div>
  );
}
