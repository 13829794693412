import { useState } from "react";
import { useLoaderData } from "react-router-dom";
import { Helmet } from "react-helmet-async";

import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Button from "react-bootstrap/Button";
import ToggleButtonGroup from "react-bootstrap/ToggleButtonGroup";
import ToggleButton from "react-bootstrap/ToggleButton";

import { useModal } from "../lib/ModalProvider";
import { useSession } from "../lib/SessionProvider.js";

import styles from "./Pricing.module.css";

import ComparePlans from "./pricing/ComparePlans";
import Competition from "./pricing/Competition";
import PricingFree from "./pricing/PricingFree";
import AdditionalAddons from "./pricing/AdditionalAddons.js";

export default function PricingPage() {
  const [period, setPeriod] = useState("annual");
  const [showComparePlans, setShowComparePlans] = useState(false);

  const { session } = useSession();
  const { openModal } = useModal();

  const rates = useLoaderData();

  const scrollTo = function (anchor) {
    document.getElementById(anchor).scrollIntoView({
      behavior: "smooth",
      block: "start",
    });
  };

  const togglePeriod = (value) => {
    setPeriod(value);
  };

  const toggleComparePlans = () => {
    setShowComparePlans((prevShow) => {
      const show = !prevShow;
      if (show) {
        scrollTo("compare-plans");
      }

      return show;
    });
  };

  const siteTag = "FCC_PRICE";
  const buttonText = {
    monthly: "Try Free For 30 Days",
    annual: "Buy Now",
  };
  const redirectUris = {
    fcc_pro: {
      monthly: "/profile/upgrade/bundle?discount_group=complete_huddle",
      annual:
        "/profile/upgrade/bundle?discount_group=complete_huddle&recurrent_charge_type=annually_standalone",
    },
    one_number: {
      monthly: "/profile/upgrade/one_number?discount_group=us:monthly",
      annual:
        "/profile/upgrade/one_number?discount_group=us:annually_standalone&recurrent_charge_type=annually_standalone",
    },
    conference_greeting:
      "/profile/upgrade/conference_greeting?discount_group=us:monthly",
    conference_moh: "/profile/upgrade/conference_moh?discount_group=us:monthly",
    toll_free: "/profile/cart?product=toll_free",
    free_account: "/profile",
  };

  function openSignupModal(buttonTag = null) {
    let redirectUri = null;
    if (buttonTag) {
      if (buttonTag === "fcc_pro" || buttonTag === "one_number") {
        redirectUri = redirectUris[buttonTag][period];
      } else {
        redirectUri = redirectUris[buttonTag];
      }
    }

    if (session.isLoggedIn) {
      window.location.assign(redirectUri);
    } else {
      const modalOptions = {
        siteTag: siteTag,
      };
      if (redirectUri !== "/profile") {
        modalOptions.redirectUri = redirectUri;
        modalOptions.mode = "paid";
      }
      openModal("signup", modalOptions);
    }
  }

  return (
    <div className={styles.pricingPage}>
      <Helmet>
        <title>Pricing | FreeConferenceCall.com</title>
        <meta
          name="description"
          content="Our revolutionary pay what you can pricing gives people free access to reliable communication tools from anywhere in the world."
        />
        <link
          rel="canonical"
          href="https://www.freeconferencecall.com/pricing"
        />
        <meta name="robots" content="index, follow" />
        <meta property="og:title" content="Pricing | FreeConferenceCall.com" />
        <meta property="og:type" content="company" />
        <meta
          property="og:url"
          content="https://www.freeconferencecall.com/pricing"
        />
        <meta
          property="og:image"
          content="https://www.freeconferencecall.com/images/fcc_logo_fb.png"
        />
        <meta iproperty="og:site_name" content="FreeConferenceCall.com" />
        <meta property="fb:admins" content="freeconferencecall" />
      </Helmet>
      <div className={styles.header}>
        <Container>
          <p>FreeConferenceCall</p>
          <h1>Choose the right plan for your meetings</h1>

          <ToggleButtonGroup
            className={styles.togglePeriod}
            type="radio"
            name="period-toggle"
            value={period}
            onChange={togglePeriod}
          >
            <ToggleButton
              id="annual-btn"
              value="annual"
              variant="white"
              checked={period === "annual"}
              className={`${styles.annual} ${
                period === "annual" ? styles.active : ""
              }`}
            >
              <strong>Annual</strong> Save over 17%!
            </ToggleButton>
            <ToggleButton
              id="monthly-btn"
              value="monthly"
              variant="white"
              checked={period === "monthly"}
              className={`${styles.monthly} ${
                period === "monthly" ? styles.active : ""
              }`}
            >
              Monthly
            </ToggleButton>
          </ToggleButtonGroup>
        </Container>
      </div>

      <section className={styles.plans}>
        <Container>
          <Row>
            <Col lg={4}>
              <div className={styles.featureBox}>
                <div className={styles.boxHeader}></div>
                <h4>
                  Free <span className={styles.boxPrice}>$0/mo</span>
                </h4>
                <div className={styles.boxAbstract}>
                  FreeConferenceCall is 100% free for unlimited audio and video
                  calling. Stay free and try our premium features later.
                </div>
                <p>
                  <Button
                    onClick={() => openSignupModal("free_account")}
                    size="lg"
                    className="w-100"
                    title="Create My Free Account"
                  >
                    Create My Free Account
                  </Button>
                </p>
                <div className={styles.boxFeatures}>
                  <h5>Features</h5>
                  <ul>
                    <li>1000 Call Participants</li>
                    <li>Unlimited Meeting Time</li>
                    <li>Phone Dial-in Numbers</li>
                    <li>Video Conferencing</li>
                    <li>Screen Sharing</li>
                    <li>Mobile & Desktop Apps</li>
                    <li>Meeting Chat</li>
                    <li>Document Sharing</li>
                    <li>Cloud Storage (1GB)</li>
                    <li>Meeting Invites</li>
                    <li>Live Support</li>
                  </ul>
                </div>
                <div className={styles.boxFooter}>
                  <Button
                    variant="light-blue"
                    size="lg"
                    onClick={() => scrollTo("additional-add-ons")}
                    className={styles.boxFooterLink}
                  >
                    See Add-ons{" "}
                    <img
                      alt="Right Arrow Icon"
                      src="/react-assets/images/pricing/Arrow-Right-blue.png"
                    />
                  </Button>
                </div>
              </div>
            </Col>
            <Col lg={4}>
              <div className={styles.featureBox}>
                <div className={styles.boxHeader}>
                  <span className={styles.boxLabel + " " + styles.labelBlue}>
                    Most Popular
                  </span>
                </div>
                <h4>
                  One Number
                  <span
                    className={styles.boxPrice + " " + styles.oneNumberPrice}
                  >
                    {rates.one_number[period].formatted_price}
                  </span>
                </h4>
                <div className={styles.boxAbstract}>
                  One Number is our best-selling feature. Make dial-in meetings
                  easy by eliminating access codes.
                </div>
                <p>
                  <Button
                    size="lg"
                    className="w-100"
                    onClick={() => openSignupModal("one_number")}
                    title={buttonText[period]}
                  >
                    {buttonText[period]}
                  </Button>
                </p>
                <div className={styles.boxFeatures}>
                  <h5>Features</h5>
                  <p>+ All Free Features Plus</p>
                  <ul>
                    <li>Private Conference Number</li>
                    <li>No Access Code Required</li>
                    <li>Host Phone Number is Automatically Recognized</li>
                    <li>
                      Real-Time Notifications When the First Caller Connects
                    </li>
                    <li>Instant Connections</li>
                  </ul>
                </div>
                <div className={styles.boxFooter}>
                  <Button
                    variant="light-blue"
                    size="lg"
                    onClick={() => scrollTo("one-number")}
                    className={styles.boxFooterLink}
                  >
                    See Features{" "}
                    <img
                      alt="Right Arrow Icon"
                      src="/react-assets/images/pricing/Arrow-Right-blue.png"
                    />
                  </Button>
                </div>
              </div>
            </Col>
            <Col lg={4}>
              <div className={styles.featureBox}>
                <div className={styles.boxHeader}>
                  <span className={styles.boxLabel + " " + styles.labelOrange}>
                    Best Deal
                  </span>
                </div>
                <h4>
                  Pro Bundle
                  <span
                    className={styles.boxPrice + " " + styles.oneNumberPrice}
                  >
                    {rates.fcc_pro[period].formatted_price}
                  </span>
                </h4>
                <div className={styles.boxAbstract}>
                  FCC Pro bundles our most popular collaborative features at the
                  most competitive price in online meetings.
                </div>
                <p>
                  <Button
                    variant="orange"
                    size="lg"
                    className="w-100"
                    onClick={() => openSignupModal("fcc_pro")}
                    title={buttonText[period]}
                  >
                    {buttonText[period]}
                  </Button>
                </p>
                <div className={styles.boxFeatures}>
                  <h5>Features</h5>
                  <p>+ All One Number Features</p>
                  <ul>
                    <li>Custom Meeting Greeting</li>
                    <li>Custom Hold Music</li>
                    <li>Cloud Storage, 10GB</li>
                    <li>Our Best Deal</li>
                  </ul>
                </div>
                <div className={styles.boxFooter}>
                  <Button
                    variant="light-blue"
                    size="lg"
                    onClick={() => scrollTo("fcc-pro")}
                    className={styles.boxFooterLink}
                  >
                    See Features{" "}
                    <img
                      alt="Right Arrow Icon"
                      src="/react-assets/images/pricing/Arrow-Right-blue.png"
                    />
                  </Button>
                </div>
              </div>
            </Col>
          </Row>
          <Row>
            <Col lg={{ span: 4, offset: 4 }}>
              <div style={{ padding: "20px" }}>
                <Button
                  variant="orange"
                  size="lg"
                  className="w-100"
                  title="Compare Plans"
                  onClick={toggleComparePlans}
                >
                  Compare Plans
                  {showComparePlans ? (
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 448 512"
                      style={{
                        width: "18px",
                        fill: "white",
                        marginLeft: "10px",
                      }}
                    >
                      <path d="M241 130.5l194.3 194.3c9.4 9.4 9.4 24.6 0 33.9l-22.7 22.7c-9.4 9.4-24.5 9.4-33.9 0L224 227.5 69.3 381.5c-9.4 9.3-24.5 9.3-33.9 0l-22.7-22.7c-9.4-9.4-9.4-24.6 0-33.9L207 130.5c9.4-9.4 24.6-9.4 33.9 0z" />
                    </svg>
                  ) : (
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 448 512"
                      style={{
                        width: "18px",
                        fill: "white",
                        marginLeft: "10px",
                      }}
                    >
                      <path d="M207 381.5L12.7 187.1c-9.4-9.4-9.4-24.6 0-33.9l22.7-22.7c9.4-9.4 24.5-9.4 33.9 0L224 284.5l154.7-154c9.4-9.3 24.5-9.3 33.9 0l22.7 22.7c9.4 9.4 9.4 24.6 0 33.9L241 381.5c-9.4 9.4-24.6 9.4-33.9 0z" />
                    </svg>
                  )}
                </Button>
              </div>
            </Col>
          </Row>
        </Container>
      </section>

      <div className="jumptarget" id="compare-plans"></div>
      {showComparePlans && <ComparePlans rates={rates} period={period} />}
      {showComparePlans && <Competition />}

      <PricingFree openSignupModal={() => openSignupModal("free_account")} />

      <Container>
        <h1 style={{ color: "var(--fcc-blue)" }}>Optional Add-Ons</h1>
      </Container>

      <div className="jumptarget" id="one-number"></div>
      <section className={styles.oneNumber}>
        <Container>
          <h2>One Number</h2>
          <p className={styles.price}>
            {rates.one_number[period].formatted_price}
          </p>
          <h3>
            A conference number that enhances the overall experience for both
            hosts and participants. Here’s a breakdown of the advantages:
          </h3>
          <Row>
            <Col lg={6} className="d-flex align-items-stretch">
              <div className={styles.featureBox}>
                <img
                  alt="Unlimited Conferencing"
                  src="/react-assets/images/pricing/Unlimited-Conferencing-orange.png"
                />
                <div className={styles.boxContent}>
                  <h4>No Access Code Required:</h4>
                  <div>
                    <ul>
                      <li>
                        <strong>Convenience:</strong> Participants can join the
                        conference more quickly and easily without needing to
                        remember or enter a separate access code.
                      </li>
                      <li>
                        <strong>Reduced Errors:</strong> Eliminates the risk of
                        dialing the wrong access code or having issues with code
                        entry, which can streamline the joining process.
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </Col>
            <Col lg={6} className="d-flex align-items-stretch">
              <div className={styles.featureBox}>
                <img
                  alt="Chat Messaging"
                  src="/react-assets/images/pricing/Chat-Messaging-orange.png"
                />
                <div className={styles.boxContent}>
                  <h4>Text Message Reminder When the First Caller Connects:</h4>
                  <div>
                    <ul>
                      <li>
                        <strong>Early Notification:</strong> The host receives
                        an immediate alert when the first participant joins,
                        allowing them to address any setup issues or start the
                        meeting promptly.
                      </li>
                      <li>
                        <strong>Preparation:</strong> Ensures that the host can
                        prepare or adjust any necessary settings before
                        additional participants join.
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </Col>
            <Col lg={6} className="d-flex align-items-stretch">
              <div className={styles.featureBox}>
                <img
                  alt="User Icon"
                  src="/react-assets/images/pricing/User-orange.png"
                />
                <div className={styles.boxContent}>
                  <h4>Automatic Host Recognition:</h4>
                  <div>
                    <strong>Streamlined Access:</strong> The system can identify
                    the host without requiring additional verification steps,
                    making it quicker and easier for the host to join the
                    conference.
                  </div>
                </div>
              </div>
            </Col>
            <Col lg={6} className="d-flex align-items-stretch">
              <div className={styles.featureBox}>
                <img
                  alt="Contact Icon"
                  src="/react-assets/images/pricing/Contact-orange.png"
                />
                <div className={styles.boxContent}>
                  <h4>Save Conference Number as a Contact:</h4>
                  <div>
                    <ul>
                      <li>
                        <strong>Ease of Use:</strong> Participants can save the
                        conference number in their phone or contact list, making
                        future meetings easier to access without needing to look
                        up the number each time.
                      </li>
                      <li>
                        <strong>Quick Access:</strong> Facilitates faster
                        connection to conferences, reducing the time spent on
                        finding and dialing the number manually.
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </Col>
          </Row>
          <h3>
            Overall, these features collectively improve the user experience by
            simplifying access, enhancing notification systems, and facilitating
            quick and easy connections.
          </h3>
          <Row>
            <Col lg={{ span: 4, offset: 4 }}>
              <div className={styles.buttonContainer}>
                <Button
                  size="lg"
                  onClick={() => openSignupModal("one_number")}
                  className="w-100"
                  title="Buy Now"
                >
                  Buy Now
                </Button>
              </div>
            </Col>
          </Row>
        </Container>
      </section>

      <div id="fcc-pro" className="jumptarget"></div>
      <section className={styles.fccPro}>
        <Container>
          <h2>FCC Pro</h2>
          <p className={styles.price}>
            {rates.fcc_pro[period].formatted_price}
          </p>
          <h3>
            Upgrade to FCC Pro for advanced features tailored to professional
            needs and larger teams.
          </h3>
          <Row>
            <Col lg={4} md={6} className="d-flex align-items-stretch">
              <div className={styles.featureBox}>
                <img
                  alt="Use Collaboration Tools"
                  src="/react-assets/images/pricing/Collaboration-Tools-orange.png"
                />
                <h4>Advanced Collaboration Tools</h4>
                <div>
                  Use interactive whiteboards, breakout rooms, and reactions for
                  more engaging meetings.
                </div>
              </div>
            </Col>
            <Col lg={4} md={6} className="d-flex align-items-stretch">
              <div className={styles.featureBox}>
                <img
                  alt="Meeting Icon"
                  src="/react-assets/images/pricing/Meeting-orange.png"
                />
                <h4>Large Meeting Capacity</h4>
                <div>
                  Host large meetings and webinars with high participant limits.
                </div>
              </div>
            </Col>
            <Col lg={4} md={6} className="d-flex align-items-stretch">
              <div className={styles.featureBox}>
                <img
                  alt="Custom Branding"
                  src="/react-assets/images/pricing/Custom-Branding-orange.png"
                />
                <h4>Custom Branding</h4>
                <div>
                  Personalize your meetings with company branding and custom
                  URLs.
                </div>
              </div>
            </Col>

            <Col lg={4} md={6} className="d-flex align-items-stretch">
              <div className={styles.featureBox}>
                <img
                  alt="Business Tools"
                  src="/react-assets/images/pricing/Business-Tools-orange.png"
                />
                <h4>Integration with Business Tools</h4>
                <div>
                  Seamlessly integrate with popular business tools and platforms
                  for streamlined workflows.
                </div>
              </div>
            </Col>
            <Col lg={4} md={6} className="d-flex align-items-stretch">
              <div className={styles.featureBox}>
                <img
                  alt="Recording Storage"
                  src="/react-assets/images/pricing/Recording-Storage-orange.png"
                />
                <h4>Extended Call Recording Storage</h4>
                <div>
                  Enjoy longer call recording storage and enhanced playback
                  features.
                </div>
              </div>
            </Col>
            <Col lg={4} md={6} className="d-flex align-items-stretch">
              <div className={styles.featureBox}>
                <img
                  alt="Extra Storage"
                  src="/react-assets/images/pricing/Extra-Storage-orange.png"
                />
                <h4>10 GB of Extra Online Storage</h4>
                <div>
                  Get an additional 10 GB of online storage for your files and
                  recordings.
                </div>
              </div>
            </Col>
          </Row>
          <Row>
            <Col lg={{ span: 4, offset: 4 }}>
              <div className={styles.buttonContainer}>
                <Button
                  variant="orange"
                  size="lg"
                  onClick={() => openSignupModal("fcc_pro")}
                  className="w-100"
                  title="Buy Now"
                >
                  Buy Now
                </Button>
              </div>
            </Col>
          </Row>
        </Container>
      </section>

      <div id="additional-add-ons" className="jumptarget"></div>
      <AdditionalAddons
        rates={rates}
        period={period}
        openSignupModal={openSignupModal}
      />

      <section>
        <Container>
          <h2>
            Not ready to pay? No worries. Our FreeConferenceCall.com community
            has you covered.
          </h2>
          <br />
          <Row>
            <Col lg={{ span: 4, offset: 4 }}>
              <div className={styles.buttonContainer}>
                <Button
                  variant="orange"
                  size="lg"
                  onClick={() => openSignupModal("free_account")}
                  className="w-100"
                  title="Create My Free Account"
                >
                  Create My Free Account
                </Button>
              </div>
            </Col>
          </Row>
        </Container>
      </section>
    </div>
  );
}
