import Container from "react-bootstrap/Container";
import Button from "react-bootstrap/Button";

import { Helmet } from "react-helmet-async";
import { useModal } from "../lib/ModalProvider";

import styles from "./YourImpact.module.css";

function YourImpactPage() {
  const { openModal } = useModal();

  function openSignupModal() {
    const modelOptions = {
      siteTag: "FCC_YOUR_IMPACT",
    };
    openModal("signup", modelOptions);
  }

  return (
    <div className={styles.yourImpactPage}>
      <Helmet>
        <title>Our Community | FreeConferenceCall.com</title>
        <meta
          name="description"
          content="Join millions of users on the network that gives back to its community. Sign up for FreeConferenceCall.com at a cost that works for you."
        />
        <link
          rel="canonical"
          href="https://www.freeconferencecall.com/how-people-use-free-conference-call"
        />
        <meta name="robots" content="index, follow" />
        <meta
          property="og:title"
          content="Our Community | FreeConferenceCall.com"
        />
        <meta property="og:type" content="company" />
        <meta
          property="og:url"
          content="https://www.freeconferencecall.com/how-people-use-free-conference-call"
        />
        <meta
          property="og:image"
          content="https://www.freeconferencecall.com/images/fcc_logo_fb.png"
        />
        <meta iproperty="og:site_name" content="FreeConferenceCall.com" />
        <meta property="fb:admins" content="freeconferencecall" />
      </Helmet>
      <Container>
        <section>
          <div className="row d-flex align-items-stretch">
            <div className="col-lg-5 d-flex justify-content-center p-3">
              <img
                className="img-fluid"
                src="/react-assets/images/your_impact/Your_Impact_Background.webp"
                alt="Your Impact Background"
                style={{ maxHeight: "100%", objectFit: "contain" }}
              />
            </div>
            <div className="col-lg-7 p-3 d-flex flex-column justify-content-center">
              <h1>Together, We Make Connections</h1>
              <p className={styles.lead}>
                At <a href="/">FreeConferenceCall.com</a>, our mission goes
                beyond providing free conferencing services. We're dedicated to
                empowering individuals, businesses, and communities to connect
                and collaborate, regardless of their location. Your
                participation in our platform not only facilitates your own
                meetings but also contributes to a larger movement of
                accessibility and communication.
              </p>
            </div>
          </div>
        </section>
        <section className={styles.ourCommitment}>
          <h2>Our Commitment to You</h2>
          <h3>
            We believe that everyone deserves the opportunity to communicate
            freely and effectively. Here’s how your use of{" "}
            <a href="/">FreeConferenceCall.com</a> is making a difference:
          </h3>
          <div className="row d-flex align-items-stretch">
            <div className="col-lg-6 order-2 order-lg-1 p-3 d-flex flex-column justify-content-center fcc-blue">
              <p className={styles.lead + " fw-bold"}>
                Empowering Collaboration
              </p>
              <p>
                Every call hosted helps teams collaborate better, driving
                innovation and productivity. Whether it’s a small business
                brainstorming session or a large organization’s strategic
                meeting, your connection fosters progress.
              </p>
              <p className={styles.lead + " fw-bold"}>Supporting Communities</p>
              <p>
                We partner with nonprofits and educational institutions,
                providing our services to help them reach their goals. By using
                our platform, you’re helping these organizations connect with
                their audiences and create positive change.
              </p>
              <p className={styles.lead + " fw-bold"}>Global Reach</p>
              <p>
                With users across the globe, your calls transcend borders,
                allowing voices from diverse backgrounds to come together. This
                global connectivity promotes understanding and cooperation among
                individuals and organizations worldwide.
              </p>
            </div>
            <div className="col-lg-6 order-1 order-lg-2 d-flex justify-content-center p-3">
              <img
                className="img-fluid"
                src="/react-assets/images/your_impact/Earth_Love.webp"
                alt="Earth Love"
                style={{ maxHeight: "100%", objectFit: "contain" }}
              />
            </div>
          </div>
        </section>

        <section>
          <h2>Our Community Helps Non-Profits & Charities Achieve More</h2>
          <h3>
            Rather than pay for conferencing, these companies choose to invest
            more into the people they help.
          </h3>
          <div className="row">
            <div className="col-lg-5 offset-lg-1 d-flex align-items-stretch">
              <div className={styles.featureBox}>
                <img
                  className="img-fluid mx-auto d-block"
                  src="/react-assets/images/your_impact/Blind_Low-Vision_Working-Age_People.webp"
                  alt="Blind and Low-Vision Working-Age People"
                />
                <div className={styles.featureText}>
                  One incredible agency uses audio and video conferencing to
                  help blind and low-vision working-age people develop skills
                  that help them with employment. They also help older
                  individuals learn skills that help them maintain their
                  independence and help children who are without sight live
                  happy and fulfilling lives. As for the savings this agency
                  receives from choosing a free service over a paid option, they
                  put that towards helping to provide surgery and other medical
                  treatments to help restore vision for many. These people
                  deserve an award. They're incredible.
                </div>
              </div>
            </div>
            <div className="col-lg-5 d-flex align-items-stretch">
              <div className={styles.featureBox}>
                <img
                  className="img-fluid mx-auto d-block"
                  src="/react-assets/images/your_impact/Start-Up_Business.webp"
                  alt="Start-Up Business"
                />
                <div className={styles.featureText}>
                  A start-up business owner that struggled with balancing her
                  budget needed a way to keep costs to a minimum while
                  leveraging cutting-edge conferencing tools. When looking for
                  ways to cut costs, FreeConferenceCall.com became her perfect
                  digital communications partner. It helps her keep in touch
                  with vendors and clients while keeping costs low. She saved
                  hundreds in annual costs by choosing a free audio and video
                  calling service over paid-for products. FreeConferenceCall.com
                  became a vital tool for allowing her company to hire new
                  employees and provide a valuable service to their community.
                </div>
              </div>
            </div>
            <div className="col-lg-5 offset-lg-1 d-flex align-items-stretch">
              <div className={styles.featureBox}>
                <img
                  className="img-fluid mx-auto d-block"
                  src="/react-assets/images/your_impact/12-Step_Support_Group.webp"
                  alt="12 Step Support Group"
                />
                <div className={styles.featureText}>
                  A 12-step support group was in need of a safe place to share
                  their journey when they were mocked for their efforts
                  recovering from addiction. They turned to
                  FreeConferenceCall.com to continue their support group over
                  conferencing lines and find a safe haven for their
                  association. Our service allowed their group to meet multiple
                  times each week to uplift one another in a secure, mobile
                  environment free from bullying. This group found the free
                  calling service to be a priceless tool in their recovery.
                </div>
              </div>
            </div>
            <div className="col-lg-5 d-flex align-items-stretch">
              <div className={styles.featureBox}>
                <img
                  className="img-fluid mx-auto d-block"
                  src="/react-assets/images/your_impact/Volunteer_Group.webp"
                  alt="Volunteer Group"
                />
                <div className={styles.featureText}>
                  When natural disasters hit close to home, a volunteer group in
                  New Jersey relies on FreeConferenceCall.com to coordinate with
                  their leaders in an extensive effort to provide catastrophe
                  relief. Their team travels all across the country to respond
                  to hurricanes, tornados and other unexpected calamities when
                  communities need them most. Their coordinated response is a
                  testament to the power FreeConferenceCall.com can offer to
                  groups providing aid during an emergency.
                </div>
              </div>
            </div>
          </div>
        </section>
        <section>
          <h2>
            Here Are Some of the Incredible Ways People Have Used The Tools to
            Care For Each Other
          </h2>
          <div className="row">
            <div className="col-lg-4 d-flex align-items-stretch">
              <div className={styles.featureBox}>
                <img
                  className="img-fluid mx-auto d-block"
                  src="/react-assets/images/your_impact/Hospice_Physician.webp"
                  alt="Hospice Physician"
                />
                <div className={styles.featureText}>
                  A hospice physician uses FreeConferenceCall.com to be there
                  for families during their most challenging times. Since he
                  hosts multiple calls each day, our conferencing platform
                  allows him to connect with families all across the country,
                  sharing crucial information, answering questions and making
                  himself available to discuss life's most difficult decisions.
                </div>
              </div>
            </div>
            <div className="col-lg-4 d-flex align-items-stretch">
              <div className={styles.featureBox}>
                <img
                  className="img-fluid mx-auto d-block"
                  src="/react-assets/images/your_impact/Coordinate_with_Doctors.webp"
                  alt="Coordinate with Doctors"
                />
                <div className={styles.featureText}>
                  A user suffering from stage four cancer used
                  FreeConferenceCall.com to coordinate with doctors and
                  specialists about his care. With FreeConferenceCall.com's
                  help, he was able to stay up to date on his case while his
                  wife continued running their business from home. He believes
                  FreeConferenceCall.com's service was able to play a role in
                  helping him completely recover from his illness.
                </div>
              </div>
            </div>
            <div className="col-lg-4 d-flex align-items-stretch">
              <div className={styles.featureBox}>
                <img
                  className="img-fluid mx-auto d-block"
                  src="/react-assets/images/your_impact/Child_with_Special_Needs.webp"
                  alt="Child with Special Needs"
                />
                <div className={styles.featureText}>
                  A parent of a child with special needs was having trouble
                  coordinating her son's care with their group of specialists.
                  FreeConferenceCall.com helped them connect with their
                  teachers, doctors and therapists all at the same time. Our
                  free conferencing software became an indispensable tool for
                  getting their team all on the same page when it was most
                  important in improving the family's quality of life.
                </div>
              </div>
            </div>
          </div>
        </section>

        <section>
          <h2>
            Freely Stay Connected with Friends, Family and Members of Your
            Community
          </h2>
          <h3>
            With FreeConferenceCall.com you're always connected to the people
            that are most important to you.
          </h3>
          <div className="row">
            <div className="col-lg-5 offset-lg-1 d-flex align-items-stretch">
              <div className={styles.featureBox}>
                <img
                  className="img-fluid mx-auto d-block"
                  src="/react-assets/images/your_impact/National_Army_Guard.webp"
                  alt="National Army Guard"
                />
                <div className={styles.featureText}>
                  When a representative from the Army National Guard wanted to
                  share the lessons he learned during his service, he needed a
                  conferencing platform that was powerful enough to support his
                  leadership meetings and training. They turned to
                  FreeConferenceCall.com as a medium for mentorship. They also
                  use FreeConferenceCall.com to stay connected with their
                  families when traveling to different parts of the world. They
                  found the free calling service to be a well-appreciated tool
                  for military families.
                </div>
              </div>
            </div>
            <div className="col-lg-5 d-flex align-items-stretch">
              <div className={styles.featureBox}>
                <img
                  className="img-fluid mx-auto d-block"
                  src="/react-assets/images/your_impact/Middle_and_High_School.webp"
                  alt="Middle and High School"
                />
                <div className={styles.featureText}>
                  A nonprofit in Maryland was having trouble providing their
                  community's middle and high school students with much-needed
                  leadership training and practice regularly. Their lean budget
                  prevented their organization from spending money and resources
                  on paid-for communication software, hindering their ability to
                  reach out to their neighbors, friends and family.
                  FreeConferenceCall.com became their remote team's most
                  reliable free-to-use collaboration platform. With the
                  resources they saved, they were able to reach even more
                  students and encourage lasting change in their community.
                </div>
              </div>
            </div>
            <div className="col-lg-5 offset-lg-1 d-flex align-items-stretch">
              <div className={styles.featureBox}>
                <img
                  className="img-fluid mx-auto d-block"
                  src="/react-assets/images/your_impact/Happy_Family.webp"
                  alt="Happy Family"
                />
                <div className={styles.featureText}>
                  A neighborhood was looking for a tool they could use to
                  communicate with their community about organized activities
                  related to school, church and volunteer services in their
                  local area. The leaders of the neighborhood association choose
                  FreeConferenceCall.com to help their youth and parents
                  coordinate with event planners ad make each gathering a united
                  celebration.
                </div>
              </div>
            </div>
            <div className="col-lg-5 d-flex align-items-stretch">
              <div className={styles.featureBox}>
                <img
                  className="img-fluid mx-auto d-block"
                  src="/react-assets/images/your_impact/Handshake.webp"
                  alt="Handshake"
                />
                <div className={styles.featureText}>
                  Employees of a government agency were looking for software
                  that could help their team stay up to date on community events
                  and allow them to discuss burgeoning societal issues while
                  traveling. FreeConferenceCall.com became their audio and video
                  conferencing tool of choice to aid in their discussion on new
                  policies. The conferencing platform helps them communicate
                  with co-workers, tend to their duties and stay on top of
                  breaking news on demand.
                </div>
              </div>
            </div>
          </div>
        </section>
      </Container>
      <section className={styles.makingADifference}>
        <Container>
          <h2>You’re Making a Difference</h2>
          <h3>
            By being part of the FreeConferenceCall.com community, you help
            people around the world. There are thousands of stories like those
            we shared above. People all over the world use our tool to help
            others, stay connected, communicate and share.
            FreeConferenceCall.com users are only able to achieve that because
            of you, our incredible community. If you have a success story, we
            want to know about it. Share your stories here.
          </h3>
          <div className="row">
            <div className="col-lg-4 offset-lg-4">
              <Button
                variant="orange"
                onClick={openSignupModal}
                size="lg"
                title="Create My Free Account"
                className="w-100"
              >
                Create My Free Account
              </Button>
            </div>
          </div>
        </Container>
      </section>
    </div>
  );
}

export default YourImpactPage;
