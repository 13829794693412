// import { Link } from "react-router-dom";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import NavDropdown from "react-bootstrap/NavDropdown";
import Button from "react-bootstrap/Button";

import { useModal } from "../lib/ModalProvider";

export default function GuestMainNavigation() {
  const { openModal } = useModal();

  function openSignupModel() {
    const modelOptions = {
      // TODO: Get the siteTag from the Global Context
      // siteTag: "FCC_YOUR_IMPACT",
    };
    openModal("signup", modelOptions);
  }

  function openJoinModal() {
    openModal("join");
  }

  function openHostModal() {
    openModal("host");
  }

  return (
    <Navbar expand="lg" className="fixed-top main-navigation">
      <Navbar.Brand href="/" className="ms-2">
        <img
          height="35"
          className="d-inline-block align-top"
          src="https://www.freeconferencecall.com/images/application_wide/general/logo/freeconferencecall-logo.svg"
          alt="FreeConferenceCall.com"
        />
      </Navbar.Brand>
      <Navbar.Toggle aria-controls="basic-navbar-nav" className="me-auto" />
      <div className="d-flex order-lg-2">
        <Button
          onClick={openHostModal}
          variant="outline-light"
          className="me-3 d-none d-lg-block"
        >
          Host
        </Button>
        <Button
          onClick={openJoinModal}
          variant="outline-light"
          className="me-3 d-none d-lg-block"
        >
          Join
        </Button>
        <Button onClick={openSignupModel} variant="orange" className="me-3">
          Sign Up
        </Button>
        <Button href="/login" variant="light" className="me-1">
          Log In
        </Button>
      </div>
      <Navbar.Collapse id="basic-navbar-nav" className="order-lg-1">
        <Nav className="main-dropdown">
          <Nav.Link href="/features">Features</Nav.Link>
          <Nav.Link href="/pricing">Pricing</Nav.Link>
          <Nav.Link href="/how-people-use-free-conference-call">
            Your Impact
          </Nav.Link>
          <NavDropdown
            title="More"
            id="basic-nav-dropdown"
            className="d-none d-lg-block"
          >
            <NavDropdown.Item href="/downloads">Downloads</NavDropdown.Item>
            <NavDropdown.Item href="/aboutus">About Us</NavDropdown.Item>
            <NavDropdown.Item href="/support">Support Center</NavDropdown.Item>
          </NavDropdown>
          <div className="d-block d-lg-none">
              <Nav.Link href="/downloads">Downloads</Nav.Link>
              <Nav.Link href="/aboutus">About Us</Nav.Link>
              <Nav.Link href="/support">Support Center</Nav.Link>
              <NavDropdown.Divider />
              <Nav.Link
                href="#"
                role="button"
                onClick={(event) => {
                  event.preventDefault();
                  openHostModal();
                }}
              >
                Host Meeting
              </Nav.Link>
              <Nav.Link
                href="#"
                role="button"
                onClick={(event) => {
                  event.preventDefault();
                  openJoinModal();
                }}
              >
                Join Meeting
              </Nav.Link>
            </div>
        </Nav>
      </Navbar.Collapse>
    </Navbar>
  );
}
