import { useEffect } from "react";
import { loadScript } from "./helpers";
import { useSession } from "./SessionProvider";

const trackingKeys = {
  fcc_pre_production: {
    ga_key: "UA-208565-19",
    gtag_key: "AW-10724915399",
    gtag_global_key: "AW-10199741469",
  },
  fcc_production: {
    ga_key: "UA-208565-1",
    gtag_key: "AW-1072491539",
    gtag_global_key: "AW-1019974146",
    bing_key: "5773337",
  },
};

export default function TrackingScripts() {
  const { session } = useSession();
  useEffect(() => {
    const projectEnv = process.env.PROJECT_ENV;
    console.log("process.env=", process.env);
    if (
      !projectEnv ||
      !["fcc_pre_production", "fcc_production"].includes(projectEnv)
    )
      return;

    const gtagKey =
      session.countryCode == "us"
        ? trackingKeys[projectEnv].gtag_key
        : trackingKeys[projectEnv].gtag_global_key;
    const gaKey = trackingKeys[projectEnv].ga_key;

    loadScript(`https://www.googletagmanager.com/gtag/js?id=${gtagKey}`);
    loadScript(`https://www.google-analytics.com/analytics.js`);

    const script = document.createElement("script");
    script.async = true;
    script.defer = true;
    script.textContent = `
      window.ga=window.ga||function(){(ga.q=ga.q||[]).push(arguments)};ga.l=+new Date;
      ga('create', '${gaKey}', 'auto');
      ga('send', 'pageview');
    `;
    document.body.appendChild(script);
  }, []);

  return null;
}
