import LoggedInMainNavigation from './LoggedInMainNavigation.js';
import GuestMainNavigation from './GuestMainNavigation.js';

import { useSession } from "../lib/SessionProvider.js";

export default function MainNavigation() {
  const { session } = useSession();

  return session.isLoggedIn ? <LoggedInMainNavigation /> : <GuestMainNavigation />;
}
