import { createContext, useContext, useState } from "react";
import Alert from "react-bootstrap/Alert";

const FlashMessageContext = createContext();

export default function FlashMessageProvider({ children }) {
  const [showMessage, setShowMessage] = useState(false);
  const [message, setMessage] = useState("");
  const [variant, setVariant] = useState("success");

  const triggerFlashMessage = (type, msg, delay = 5) => {
    setVariant(type);
    setMessage(msg);
    setShowMessage(true);

    setTimeout(() => setShowMessage(false), delay * 1000);
  };

  return (
    <FlashMessageContext.Provider value={{ triggerFlashMessage }}>
      {showMessage && (
        <div
          style={{
            position: "fixed",
            top: 20,
            left: "50%",
            transform: "translateX(-50%)",
            zIndex: 1075,
          }}
        >
          <Alert
            variant={variant}
            onClose={() => setShowMessage(false)}
            dismissible
          >
            {message}
          </Alert>
        </div>
      )}
      {children}
    </FlashMessageContext.Provider>
  );
}

// Custom hook to use the FlashMessageContext
export const useFlashMessage = () => useContext(FlashMessageContext);
