import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

import { WindowsIcon, AppleIcon } from "../../lib/Icons";

import styles from "../Downloads.module.css";

export default function IOSSellbox() {
  return (
    <section className={styles.sellBox}>
      <Row>
        <Col lg={7} className="order-2 order-lg-1">
          <AppleIcon />
          <h2>Download iOS App</h2>
          <p className={styles.boxAbstract}>
            Host and manage conference calls from the convenience of your
            iPhone®, iPad® or iPad Pro®, or join in on a screen sharing and
            video conferencing session.
          </p>
          <p className={styles.boxImageIcon}>
            <a
              target="_blank"
              rel="noreferrer"
              title="Download the Mobile App"
              href="https://apps.apple.com/us/app/free-conference-call/id1076834814"
            >
              <img
                alt="Apple App Store"
                src="/react-assets/images/homepage/Apple_App_Store.png"
              />
            </a>
          </p>
          <p className={styles.lead + " fcc-blue"}>Also available on:</p>
          <Row>
            <Col className="text-center">
              <WindowsIcon />
              <p className={styles.lead}>Windows</p>
            </Col>
            <Col className="text-center">
              <AppleIcon />
              <p className={styles.lead}>macOS®</p>
            </Col>
          </Row>
        </Col>
        <Col lg={5} className="order-1 order-lg-2">
          <img
            alt="iOS Phone"
            className={styles.boxImage + " img-fluid d-block mx-auto"}
            src="/react-assets/images/downloads/iOS_Sellbox.jpg"
          />
        </Col>
      </Row>
    </section>
  );
}
