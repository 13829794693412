export const WindowsIcon = ({
  fill = "var(--fcc-blue)",
  width = 50,
  height = 50,
}) => (
  <svg
    width={width}
    height={height}
    fill={fill}
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 448 512"
  >
    <path d="M0 93.7l183.6-25.3v177.4H0V93.7zm0 324.6l183.6 25.3V268.4H0v149.9zm203.8 28L448 480V268.4H203.8v177.9zm0-380.6v180.1H448V32L203.8 65.7z" />
  </svg>
);

export const AppleIcon = ({
  fill = "var(--fcc-blue)",
  width = 50,
  height = 50,
}) => (
  <svg
    width={width}
    height={height}
    fill={fill}
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 384 512"
  >
    <path d="M318.7 268.7c-.2-36.7 16.4-64.4 50-84.8-18.8-26.9-47.2-41.7-84.7-44.6-35.5-2.8-74.3 20.7-88.5 20.7-15 0-49.4-19.7-76.4-19.7C63.3 141.2 4 184.8 4 273.5q0 39.3 14.4 81.2c12.8 36.7 59 126.7 107.2 125.2 25.2-.6 43-17.9 75.8-17.9 31.8 0 48.3 17.9 76.4 17.9 48.6-.7 90.4-82.5 102.6-119.3-65.2-30.7-61.7-90-61.7-91.9zm-56.6-164.2c27.3-32.4 24.8-61.9 24-72.5-24.1 1.4-52 16.4-67.9 34.9-17.5 19.8-27.8 44.3-25.6 71.9 26.1 2 49.9-11.4 69.5-34.3z" />
  </svg>
);

export const AndroidIcon = ({
  fill = "var(--fcc-blue)",
  width = 50,
  height = 50,
}) => (
  <svg
    width={width}
    height={height}
    fill={fill}
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 553.048 553.048"
  >
    <g>
      <g>
        <path
          d="M76.774,179.141c-9.529,0-17.614,3.323-24.26,9.969c-6.646,6.646-9.97,14.621-9.97,23.929v142.914
c0,9.541,3.323,17.619,9.97,24.266c6.646,6.646,14.731,9.97,24.26,9.97c9.522,0,17.558-3.323,24.101-9.97
c6.53-6.646,9.804-14.725,9.804-24.266V213.039c0-9.309-3.323-17.283-9.97-23.929C94.062,182.464,86.082,179.141,76.774,179.141z"
        />
        <path
          d="M351.972,50.847L375.57,7.315c1.549-2.882,0.998-5.092-1.658-6.646c-2.883-1.34-5.098-0.661-6.646,1.989l-23.928,43.88
c-21.055-9.309-43.324-13.972-66.807-13.972c-23.488,0-45.759,4.664-66.806,13.972l-23.929-43.88
c-1.555-2.65-3.77-3.323-6.646-1.989c-2.662,1.561-3.213,3.764-1.658,6.646l23.599,43.532
c-23.929,12.203-42.987,29.198-57.167,51.022c-14.18,21.836-21.273,45.698-21.273,71.628h307.426
c0-25.924-7.094-49.787-21.273-71.628C394.623,80.045,375.675,63.05,351.972,50.847z M215.539,114.165
c-2.552,2.558-5.6,3.831-9.143,3.831c-3.55,0-6.536-1.273-8.972-3.831c-2.436-2.546-3.654-5.582-3.654-9.137
c0-3.543,1.218-6.585,3.654-9.137c2.436-2.546,5.429-3.819,8.972-3.819s6.591,1.273,9.143,3.819
c2.546,2.558,3.825,5.594,3.825,9.137C219.357,108.577,218.079,111.619,215.539,114.165z M355.625,114.165
c-2.441,2.558-5.434,3.831-8.971,3.831c-3.551,0-6.598-1.273-9.145-3.831c-2.551-2.546-3.824-5.582-3.824-9.137
c0-3.543,1.273-6.585,3.824-9.137c2.547-2.546,5.594-3.819,9.145-3.819c3.543,0,6.529,1.273,8.971,3.819
c2.438,2.558,3.654,5.594,3.654,9.137C359.279,108.577,358.062,111.619,355.625,114.165z"
        />
        <path
          d="M123.971,406.804c0,10.202,3.543,18.838,10.63,25.925c7.093,7.087,15.729,10.63,25.924,10.63h24.596l0.337,75.454
c0,9.528,3.323,17.619,9.969,24.266s14.627,9.97,23.929,9.97c9.523,0,17.613-3.323,24.26-9.97s9.97-14.737,9.97-24.266v-75.447
h45.864v75.447c0,9.528,3.322,17.619,9.969,24.266s14.73,9.97,24.26,9.97c9.523,0,17.613-3.323,24.26-9.97
s9.969-14.737,9.969-24.266v-75.447h24.928c9.969,0,18.494-3.544,25.594-10.631c7.086-7.087,10.631-15.723,10.631-25.924V185.45
H123.971V406.804z"
        />
        <path
          d="M476.275,179.141c-9.309,0-17.283,3.274-23.93,9.804c-6.646,6.542-9.969,14.578-9.969,24.094v142.914
c0,9.541,3.322,17.619,9.969,24.266s14.627,9.97,23.93,9.97c9.523,0,17.613-3.323,24.26-9.97s9.969-14.725,9.969-24.266V213.039
c0-9.517-3.322-17.552-9.969-24.094C493.888,182.415,485.798,179.141,476.275,179.141z"
        />
      </g>
    </g>
  </svg>
);

export const CalendarIcon = ({
  fill = "var(--fcc-blue)",
  width = 50,
  height = 50,
}) => (
  <svg
    width={width}
    height={height}
    fill={fill}
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 448 512"
  >
    <path d="M0 464c0 26.5 21.5 48 48 48h352c26.5 0 48-21.5 48-48V192H0v272zm320-196c0-6.6 5.4-12 12-12h40c6.6 0 12 5.4 12 12v40c0 6.6-5.4 12-12 12h-40c-6.6 0-12-5.4-12-12v-40zm0 128c0-6.6 5.4-12 12-12h40c6.6 0 12 5.4 12 12v40c0 6.6-5.4 12-12 12h-40c-6.6 0-12-5.4-12-12v-40zM192 268c0-6.6 5.4-12 12-12h40c6.6 0 12 5.4 12 12v40c0 6.6-5.4 12-12 12h-40c-6.6 0-12-5.4-12-12v-40zm0 128c0-6.6 5.4-12 12-12h40c6.6 0 12 5.4 12 12v40c0 6.6-5.4 12-12 12h-40c-6.6 0-12-5.4-12-12v-40zM64 268c0-6.6 5.4-12 12-12h40c6.6 0 12 5.4 12 12v40c0 6.6-5.4 12-12 12H76c-6.6 0-12-5.4-12-12v-40zm0 128c0-6.6 5.4-12 12-12h40c6.6 0 12 5.4 12 12v40c0 6.6-5.4 12-12 12H76c-6.6 0-12-5.4-12-12v-40zM400 64h-48V16c0-8.8-7.2-16-16-16h-32c-8.8 0-16 7.2-16 16v48H160V16c0-8.8-7.2-16-16-16h-32c-8.8 0-16 7.2-16 16v48H48C21.5 64 0 85.5 0 112v48h448v-48c0-26.5-21.5-48-48-48z" />
  </svg>
);

export const OutlookCalendarIcon = ({
  fill = "var(--fcc-blue)",
  width = 50,
  height = 50,
}) => (
  <svg
    width={width}
    height={height}
    fill={fill}
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 50 47"
  >
    <path d="M48.4375 9.37711H28.125V1.56461C28.125 1.09898 27.9188 0.658355 27.5594 0.361481C27.2031 0.0646055 26.725 -0.0635195 26.275 0.0302305L1.275 4.71773C0.534375 4.85523 0 5.49898 0 6.25211V40.6271C0 41.3771 0.534375 42.024 1.275 42.1615L26.275 46.849C26.3687 46.8677 26.4656 46.8771 26.5625 46.8771C26.925 46.8771 27.2781 46.7521 27.5594 46.5177C27.9188 46.2209 28.125 45.7771 28.125 45.3146V37.5021H48.4375C49.3 37.5021 50 36.8021 50 35.9396V10.9396C50 10.0771 49.3 9.37711 48.4375 9.37711ZM14.0625 34.3771C9.75625 34.3771 6.25 30.1709 6.25 25.0021C6.25 19.8334 9.75625 15.6271 14.0625 15.6271C18.3687 15.6271 21.875 19.8334 21.875 25.0021C21.875 30.1709 18.3687 34.3771 14.0625 34.3771ZM28.125 12.5021H43.8844L34.2844 19.9677L28.125 16.0521V12.5021ZM46.875 34.3771H28.125V19.7552L33.5344 23.1959C33.7906 23.3584 34.0844 23.4396 34.375 23.4396C34.7125 23.4396 35.0531 23.3302 35.3344 23.1115L46.875 14.1334V34.3771Z" />
    <path d="M14.0625 31.252C16.6513 31.252 18.75 28.4538 18.75 25.002C18.75 21.5502 16.6513 18.752 14.0625 18.752C11.4737 18.752 9.375 21.5502 9.375 25.002C9.375 28.4538 11.4737 31.252 14.0625 31.252Z" />
  </svg>
);

export const IntegrationsIcon = ({
  fill = "var(--fcc-blue)",
  width = 50,
  height = 50,
}) => (
  <svg
    width={width}
    height={height}
    fill={fill}
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 384 512"
  >
    <path d="M384 144c0-44.2-35.8-80-80-80s-80 35.8-80 80c0 36.4 24.3 67.1 57.5 76.8-.6 16.1-4.2 28.5-11 36.9-15.4 19.2-49.3 22.4-85.2 25.7-28.2 2.6-57.4 5.4-81.3 16.9v-144c32.5-10.2 56-40.5 56-76.3 0-44.2-35.8-80-80-80S0 35.8 0 80c0 35.8 23.5 66.1 56 76.3v199.3C23.5 365.9 0 396.2 0 432c0 44.2 35.8 80 80 80s80-35.8 80-80c0-34-21.2-63.1-51.2-74.6 3.1-5.2 7.8-9.8 14.9-13.4 16.2-8.2 40.4-10.4 66.1-12.8 42.2-3.9 90-8.4 118.2-43.4 14-17.4 21.1-39.8 21.6-67.9 31.6-10.8 54.4-40.7 54.4-75.9zM80 64c8.8 0 16 7.2 16 16s-7.2 16-16 16-16-7.2-16-16 7.2-16 16-16zm0 384c-8.8 0-16-7.2-16-16s7.2-16 16-16 16 7.2 16 16-7.2 16-16 16zm224-320c8.8 0 16 7.2 16 16s-7.2 16-16 16-16-7.2-16-16 7.2-16 16-16z" />
  </svg>
);

export const DownloadArrow = ({
  fill = "var(--fcc-blue)",
  width = 30,
  height = 30,
}) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 26 26"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect width="26" height="26" rx="4" fill="#E8E8E8" />
    <path
      d="M18.2367 12.2467C18.5296 12.5396 18.5296 13.0145 18.2367 13.3074L13.7072 17.8369C13.3167 18.2274 12.6835 18.2274 12.293 17.8369L7.76352 13.3074C7.47061 13.0145 7.47061 12.5396 7.76352 12.2467C8.05642 11.9538 8.53131 11.9538 8.82421 12.2467L12.2501 15.6726V6.01666C12.2501 5.60245 12.5859 5.26666 13.0001 5.26666C13.4143 5.26666 13.7501 5.60245 13.7501 6.01666V15.6726L17.176 12.2467C17.4689 11.9538 17.9438 11.9538 18.2367 12.2467ZM18.767 20.15C18.767 19.7358 18.4313 19.4 18.017 19.4H7.98316C7.56894 19.4 7.23315 19.7358 7.23315 20.15C7.23315 20.5642 7.56894 20.9 7.98315 20.9H18.017C18.4313 20.9 18.767 20.5642 18.767 20.15Z"
      fill="#8993A4"
    />
  </svg>
);
