import { useState, useEffect } from "react";
import { generateRandomId } from "../lib/helpers";
import { useSession } from "../lib/SessionProvider.js";
import {
  getCookie,
  setCookie,
  deleteCookie,
  isEUCountry,
} from "../lib/helpers";

import Form from "react-bootstrap/Form";

export default function TermsCheckbox({ onAcceptChange, countryCode, ...props }) {
  // const [checked, setChecked] = useState(false);
  // const [showCheckbox, setShowCheckbox] = useState(false);

  const { session, updateSession } = useSession();

  const setAccepted = (isAccepted) => {
    updateSession({
      acceptedTerms: isAccepted,
    });
  };

  const handleChange = (event) => {
    const isChecked = event.target.checked;
    setAccepted(isChecked);

    if (isChecked) {
      setCookie("accept_terms_time", Math.round(+new Date() / 1000), 365 * 10);
    } else {
      deleteCookie("accept_terms_time");
    }
  };

  useEffect(() => {
      onAcceptChange(session.acceptedTerms);
  }, [session.acceptedTerms]);

  useEffect(() => {
    if (session.showTerms) {
      // setShowCheckbox(true);
      const acceptTermsTime = getCookie("accept_terms_time");
      if (acceptTermsTime !== null) {
        setAccepted(true);
        onAcceptChange(true);
      }
    } else {
      onAcceptChange(true);
    }
  }, []);

  if (!session.showTerms) return null;

  return (
    <Form.Group {...props}>
      <Form.Check
        id={generateRandomId()}
        type="checkbox"
        checked={session.acceptedTerms}
        onChange={handleChange}
        label={
          <>
            I agree to the <a href="/terms-of-service">Terms of Service</a> and
            how my personal data is collected and protected.
          </>
        }
      />
    </Form.Group>
  );
}
