import LoggedInFooter from './LoggedInFooter.js';
import GuestFooter from './GuestFooter.js';

import { useSession } from "../lib/SessionProvider.js";

export default function Footer() {
    const { session } = useSession();

    return session.isLoggedIn ? <LoggedInFooter /> : <GuestFooter />;
}
