import Container from "react-bootstrap/Container";
import Col from "react-bootstrap/Col";
import Button from "react-bootstrap/Button";
import Row from "react-bootstrap/Row";

import styles from "./AdditionalAddons.module.css";

export default function AdditionalAddons({ rates, period, openSignupModal }) {
  return (
    <section className={styles.additionalAddons}>
      <Container>
        <h2>Additional Add-ons</h2>
        <h3>
          Enhance your FreeConferenceCall experience with these optional add-ons
          to tailor your service to your specific needs.
        </h3>
        <Row>
          <Col lg={3} md={6} className="d-flex align-items-stretch">
            <div className={styles.featureBox}>
              <img
                alt="One Number Icon"
                src="/react-assets/images/pricing/One-Number-orange.png"
              />
              <h4>One Number</h4>
              <p className={styles.featurePrice}>
                {rates.one_number[period].formatted_price}
              </p>
              <p>
                Simplify your communication with a single, dedicated number for
                all your conferencing needs. Perfect for consolidating contact
                points.
              </p>
              <div className={styles.boxFooter}>
                <p>
                  <Button
                    variant="outline-primary"
                    size="lg"
                    href="/one-number-3"
                    className="w-100"
                    title="Learn More"
                  >
                    Learn More
                  </Button>
                </p>
                <Button
                  size="lg"
                  onClick={() => openSignupModal("one_number")}
                  className="w-100"
                  title="Buy Now"
                >
                  Buy Now
                </Button>
              </div>
            </div>
          </Col>
          <Col lg={3} md={6} className="d-flex align-items-stretch">
            <div className={styles.featureBox}>
              <img
                alt="Toll-Free Numbers Icon"
                src="/react-assets/images/pricing/Toll-Free-Numbers-orange.png"
              />
              <h4>Toll-Free Numbers</h4>
              <p className={styles.featurePrice}>
                {rates.toll_free.formatted_price}
              </p>
              <p>
                Offer your clients and partners a hassle-free way to reach you
                with toll-free numbers. Ideal for businesses looking to provide
                a professional and accessible contact option.
              </p>
              <div className={styles.boxFooter}>
                <p>
                  <Button
                    variant="outline-primary"
                    size="lg"
                    href="/toll-free"
                    className="w-100"
                    title="Learn More"
                  >
                    Learn More
                  </Button>
                </p>
                <Button
                  size="lg"
                  onClick={() => openSignupModal("toll_free")}
                  className="w-100"
                  title="Buy Now"
                >
                  Buy Now
                </Button>
              </div>
            </div>
          </Col>
          <Col lg={3} md={6} className="d-flex align-items-stretch">
            <div className={styles.featureBox}>
              <img
                alt="Greeting Icon"
                src="/react-assets/images/pricing/Custom-Greeting-orange.png"
              />
              <h4>Custom Greeting</h4>
              <p className={styles.featurePrice}>
                {rates.conference_greeting.formatted_price}
              </p>
              <p>
                Make a great first impression with a personalized greeting for
                your callers. Customize the message to reflect your brand or
                provide important information.
              </p>
              <div className={styles.boxFooter}>
                <Button
                  size="lg"
                  onClick={() => openSignupModal("conference_greeting")}
                  className="w-100"
                  title="Buy Now"
                >
                  Buy Now
                </Button>
              </div>
            </div>
          </Col>
          <Col lg={3} md={6} className="d-flex align-items-stretch">
            <div className={styles.featureBox}>
              <img
                alt="Hold Music Icon"
                src="/react-assets/images/pricing/Custom-Hold-Music-orange.png"
              />
              <h4>Custom Hold Music</h4>
              <p className={styles.featurePrice}>
                {rates.conference_moh.formatted_price}
              </p>
              <p>
                Keep your callers entertained and engaged with custom hold
                music. Choose or upload your own tracks to provide a branded and
                pleasant waiting experience.
              </p>
              <div className={styles.boxFooter}>
                <Button
                  size="lg"
                  onClick={() => openSignupModal("conference_moh")}
                  className="w-100"
                  title="Buy Now"
                >
                  Buy Now
                </Button>
              </div>
            </div>
          </Col>
        </Row>
        <h3>
          Select the add-ons that best fit your needs to enhance your
          FreeConferenceCall experience!
        </h3>
      </Container>
    </section>
  );
}
