import Container from "react-bootstrap/Container";
import Col from "react-bootstrap/Col";
import Button from "react-bootstrap/Button";
import Row from "react-bootstrap/Row";

import styles from "./PricingFree.module.css";

export default function PricingFree({ openSignupModal }) {

  return (
    <section className={styles.pricingFree}>
      <Container>
        <h2>Free</h2>
        <h3>
          Experience all the essential features you need for effective virtual
          meetings, completely free.
        </h3>
        <Row>
          <Col lg={3} md={4}>
            <div className={styles.featureBox}>
              <img
                alt="Host Unlimited Icon"
                src="/react-assets/images/pricing/Unlimited-Conferencing-orange.png"
              />
              <h4>Unlimited Conferencing</h4>
              <div className={styles.boxText}>
                Host unlimited conference calls and webinars with no time
                limits.
              </div>
            </div>
          </Col>
          <Col lg={3} md={4}>
            <div className={styles.featureBox}>
              <img
                alt="Enjoy HD Audio and Video"
                src="/react-assets/images/pricing/HD-Audio-Video-orange.png"
              />
              <h4>HD Audio & Video</h4>
              <div className={styles.boxText}>
                Enjoy high-definition audio and video quality for clear and
                professional communication.
              </div>
            </div>
          </Col>
          <Col lg={3} md={4}>
            <div className={styles.featureBox}>
              <img
                alt="Share Your Screen Icon"
                src="/react-assets/images/pricing/Screen-Sharing-orange.png"
              />
              <h4>Screen Sharing</h4>
              <div className={styles.boxText}>
                Share your screen easily to collaborate on documents and
                presentations in real-time.
              </div>
            </div>
          </Col>
          <Col lg={3} md={4}>
            <div className={styles.featureBox}>
              <img
                alt="Record Your Calls Icon"
                src="/react-assets/images/pricing/Call-Recording-orange.png"
              />
              <h4>Call Recording</h4>
              <div className={styles.boxText}>
                Record your calls and meetings for future reference or to share
                with absent team members.
              </div>
            </div>
          </Col>
          <Col lg={3} md={4}>
            <div className={styles.featureBox}>
              <img
                alt="Schedule Meetings Icon"
                src="/react-assets/images/pricing/Meeting-Scheduling-orange.png"
              />
              <h4>Meeting Scheduling</h4>
              <div className={styles.boxText}>
                Schedule meetings effortlessly with integrated calendar tools
                and reminders.
              </div>
            </div>
          </Col>
          <Col lg={3} md={4}>
            <div className={styles.featureBox}>
              <img
                alt="Mobile Apps Icon"
                src="/react-assets/images/pricing/Mobile-Access-orange.png"
              />
              <h4>Mobile Access</h4>
              <div className={styles.boxText}>
                Join or host meetings from anywhere with our free mobile apps
                for iOS and Android.
              </div>
            </div>
          </Col>
          <Col lg={3} md={4}>
            <div className={styles.featureBox}>
              <img
                alt="Connect with Participants Icon"
                src="/react-assets/images/pricing/International-Dial-In-Numbers-orange.png"
              />
              <h4>International Dial-In Numbers</h4>
              <div className={styles.boxText}>
                Connect with participants globally using local dial-in numbers.
              </div>
            </div>
          </Col>
          <Col lg={3} md={4}>
            <div className={styles.featureBox}>
              <img
                alt="Access Free Support Icon"
                src="/react-assets/images/pricing/Technical-Support-orange.png"
              />
              <h4>Technical Support</h4>
              <div className={styles.boxText}>
                Access free technical support to ensure your meetings run
                smoothly.
              </div>
            </div>
          </Col>
          <Col lg={3} md={4}>
            <div className={styles.featureBox}>
              <img
                alt="Enhance Your Video Icon"
                src="/react-assets/images/pricing/Virtual-Backgrounds-orange.png"
              />
              <h4>Virtual Backgrounds</h4>
              <div className={styles.boxText}>
                Enhance your video presence with customizable virtual
                backgrounds.
              </div>
            </div>
          </Col>
          <Col lg={3} md={4}>
            <div className={styles.featureBox}>
              <img
                alt="Personalize Your Meeting Icon"
                src="/react-assets/images/pricing/Customizable-Meeting-Links-orange.png"
              />
              <h4>Customizable Meeting Links</h4>
              <div className={styles.boxText}>
                Personalize your meeting URLs for easy access and a professional
                touch.
              </div>
            </div>
          </Col>
          <Col lg={3} md={4}>
            <div className={styles.featureBox}>
              <img
                alt="Communicate Through Icon"
                src="/react-assets/images/pricing/Chat-Messaging-orange.png"
              />
              <h4>Chat & Messaging</h4>
              <div className={styles.boxText}>
                Communicate through in-meeting chat to share messages, links,
                and files.
              </div>
            </div>
          </Col>
          <Col lg={3} md={4}>
            <div className={styles.featureBox}>
              <img
                alt="Manage Attendees Icon"
                src="/react-assets/images/pricing/Participant-Management-orange.png"
              />
              <h4>Participant Management</h4>
              <div className={styles.boxText}>
                Manage attendees with features like muting, locking the meeting,
                and setting roles.
              </div>
            </div>
          </Col>
        </Row>
        <Row>
          <Col lg={{ span: 4, offset: 4 }}>
            <div style={{ padding: "20px" }}>
              <Button
                size="lg"
                onClick={openSignupModal}
                className="w-100"
                title="Create My Free Account"
              >
                Create My Free Account
              </Button>
            </div>
          </Col>
        </Row>
      </Container>
    </section>
  );
}
