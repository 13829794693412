import { useState } from "react";
import { useLoaderData } from "react-router-dom";
import { Helmet } from "react-helmet-async";

import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Button from "react-bootstrap/Button";
import ToggleButtonGroup from "react-bootstrap/ToggleButtonGroup";
import ToggleButton from "react-bootstrap/ToggleButton";
import Badge from "react-bootstrap/Badge";

import NoLimits from "../components/NoLimits.js";

import { useModal } from "../lib/ModalProvider.js";
import { useSession } from "../lib/SessionProvider.js";

import styles from "./Upgrades.module.css";

export default function UpgradesPage() {
  const [period, setPeriod] = useState("annual");

  const { session } = useSession();
  const { openModal } = useModal();

  const data = useLoaderData();
  const rates = data.rates;

  const defaultStorateRate = rates.extra_storage.find((rate) => {
    return rate.amount === 80;
  });
  const [storageRateId, setStorageRate] = useState(defaultStorateRate.id);

  const country = data.countries.find(
    (country) => country.code === session.countryCode
  );
  const currencyCode = country ? country.currency_code : "USD";
  const localCurrency = data.currencies.find(
    (currency) => currency.code === currencyCode
  );
  const defaultCurrency = data.currencies.find(
    (currency) => currency.code === "USD"
  );

  function formatPrice(price, currency) {
    return currency.format_pattern.replace("%s", price);
  }

  function formatAmount(amount) {
    return amount < 1000 ? amount + "GB" : Math.round(amount / 1000, 1) + "TB";
  }

  function getLocalPrice(rate) {
    let currency = localCurrency;
    const countryRate = rate.fees_recurrent.find(
      (countryRate) => countryRate.currency_code === currency.code
    );

    let value;
    if (countryRate) {
      value = countryRate.price;
    } else {
      value = rate.recurrent_fee;
      currency = defaultCurrency;
    }

    if (
      period === "annual" &&
      (rate.attributes.fcc_pro || rate.attributes.one_number)
    ) {
      value = Math.ceil((value * 100) / 12) / 100;
    }

    return formatPrice(value, currency);
  }

  const togglePeriod = (value) => {
    setPeriod(value);
  };

  const siteTag = "FCC_PPC_Pay_1";
  const redirectUris = {
    annual_plan:
      '/profile/upgrade/bundle?discount_group=complete_huddle&recurrent_charge_type=annually_standalone&campaign_tag=PPC_CAR_3143_SB',
    free_account: '/profile?campaign_tag=PPC_CAR_3143_Free',
    fcc_pro: {
      annual:
        '/profile/upgrade/bundle?discount_group=complete_huddle&recurrent_charge_type=annually_standalone&campaign_tag=PPC_CAR_3143_Pro_AN',
      monthly:
        '/profile/upgrade/bundle?discount_group=complete_huddle&campaign_tag=PPC_CAR_3143_Pro_MO',
    },
    one_number: {
      annual:
        '/profile/upgrade/one_number?discount_group=us:annually_standalone&recurrent_charge_type=annually_standalone&campaign_tag=PPC_CAR_3143_ON_AN',
      monthly:
        '/profile/upgrade/one_number?discount_group=us:monthly&campaign_tag=PPC_CAR_3143_ON_MO',
    },
    free_account_2: '/profile?campaign_tag=PPC_CAR_3143_Free_2',
    conference_greeting:
      '/profile/upgrade/conference_greeting?discount_group=us:monthly&campaign_tag=PPC_CAR_3143_CG',
    conference_moh:
      '/profile/upgrade/conference_moh?discount_group=us:monthly&campaign_tag=PPC_CAR_3143_MOH',
    extra_storage:
      '/profile/upgrade/extra_storage?discount_group=us:monthly&campaign_tag=PPC_CAR_3143_ES&rate_id={rate_id}',
  };

  function getRedirectUri(buttonTag) {
    let redirectUri;
    if (buttonTag === 'fcc_pro' || buttonTag === 'one_number') {
      redirectUri = redirectUris[buttonTag][period];
    } else {
      redirectUri = redirectUris[buttonTag];
    }

    if (buttonTag === 'extra_storage') {
      redirectUri = redirectUri.replace('{rate_id}', storageRateId);
    }
    return redirectUri;
  };

  function redirectToSignupPopup(buttonTag) {
    const redirectUri = getRedirectUri(buttonTag);

    if (session.isLoggedIn) {
      window.location.assign(redirectUri);
    } else {
      const modalOptions = {
        siteTag: siteTag,
        redirectUri: redirectUri,
      };

      const params = new URL(window.location.href).searchParams;
      const marketingTag = params.get('marketing_tag');
      if (marketingTag) {
        modalOptions.marketingTag = marketingTag;
      }
      const isPaid =
        buttonTag !== 'free_account' && buttonTag !== 'free_account_2';
      if (isPaid) {
        modalOptions.mode = "paid";
      }

      openModal("signup", modalOptions);
    }
  };

  return (
    <div className={styles.upgradesPage}>
      <Helmet>
        <title>Upgrades | FreeConferenceCall.com</title>
        <meta
          name="description"
          content="Explore our FCC Pro Bundle or customize your own upgrades to elevate your meeting experience."
        />
        <link
          rel="canonical"
          href="https://www.freeconferencecall.com/upgrades"
        />
        <meta name="robots" content="index, follow" />
        <meta property="og:title" content="Upgrades | FreeConferenceCall.com" />
        <meta property="og:type" content="company" />
        <meta
          property="og:url"
          content="https://www.freeconferencecall.com/upgrades"
        />
        <meta
          property="og:image"
          content="https://www.freeconferencecall.com/images/fcc_logo_fb.png"
        />
        <meta iproperty="og:site_name" content="FreeConferenceCall.com" />
        <meta property="fb:admins" content="freeconferencecall" />
      </Helmet>
      <section className={styles.header}>
        <Container>
          <Row className="d-flex align-items-stretch">
            <Col
              lg={7}
              className="order-2 order-lg-1 d-flex flex-column justify-content-center"
            >
              <h1>
                Upgrade your conferencing experience with FreeConferenceCall Pro
              </h1>
              <p className={styles.lead}>
                Explore the robust features included with our FCC Pro bundle to
                take your meetings to the next level
              </p>
              <Row>
                <Col lg={7}>
                  <Button
                    variant="orange"
                    size="lg"
                    className="w-100"
                    title="Get Started with an Annual Plan"
                    onClick={() => redirectToSignupPopup("annual_plan")}
                  >
                    Get Started with an Annual Plan
                  </Button>
                </Col>
                <Col lg={5}>
                  <Button
                    variant="outline-orange"
                    size="lg"
                    className="w-100"
                    title="Create Free Account"
                    onClick={() => redirectToSignupPopup("free_account")}
                  >
                    Create Free Account
                  </Button>
                </Col>
              </Row>
            </Col>
            <Col
              lg={5}
              className="order-1 order-lg-2 d-flex flex-column justify-content-center"
            >
              <img
                alt="Conference Call"
                className="img-fluid d-block mx-auto"
                src="/images/upgrades/conference_call.png"
              />
            </Col>
          </Row>
        </Container>
      </section>
      <Container>
        <section className={styles.bundleSave}>
          <ToggleButtonGroup
            className={styles.togglePeriod}
            type="radio"
            name="period-toggle"
            value={period}
            onChange={togglePeriod}
          >
            <ToggleButton
              id="annual-btn"
              value="annual"
              variant="white"
              checked={period === "annual"}
              className={`${styles.annual} ${
                period === "annual" ? styles.active : ""
              }`}
            >
              Annual
            </ToggleButton>
            <ToggleButton
              id="monthly-btn"
              value="monthly"
              variant="white"
              checked={period === "monthly"}
              className={`${styles.monthly} ${
                period === "monthly" ? styles.active : ""
              }`}
            >
              Monthly
            </ToggleButton>
          </ToggleButtonGroup>
          <h2>Bundle & Save</h2>
          <p className={styles.lead}>
            Create the best meeting experience for only{" "}
            <big>{getLocalPrice(rates.fcc_pro[period])}/mo</big>{" "}
            {period === "annual" && "billed annually"}
          </p>
        </section>
        <section className={styles.fccPro}>
          <Row className="d-flex align-items-stretch">
            <Col
              lg={6}
              className="order-2 order-lg-1 d-flex flex-column justify-content-center"
            >
              <p>
                <Badge bg="primary" className={styles.blueBadge}>
                  Best Deal!
                </Badge>
              </p>
              <h2>FCC Pro</h2>
              <ul>
                <li>
                  <strong>Upgraded Video Conferencing:</strong>
                  <br />
                  Complete with virtual backgrounds.
                </li>
                <li>
                  <strong>Upgraded Audio Conferencing:</strong>
                  <br />
                  Complete with a dedicated, access code free dial-in number.
                </li>
                <li>
                  <strong>Enhanced Notifications:</strong>
                  <br />
                  Including text notifications to alert you when your first
                  participant joins.
                </li>
                <li>
                  <strong>Increased Customizations:</strong>
                  <br />
                  Including custom greeting and hold music.
                </li>
                <li>
                  <strong>Extra Cloud Storage:</strong>
                  <br />
                  Get 10GB of additional cloud storage for recording your
                  meetings or uploading files.
                </li>
                <li>
                  <strong>A Huge Smile:</strong>
                  <br />
                  Knowing this amount helps us help a classroom of students.
                </li>
              </ul>
              <Row>
                <Col lg={6}>
                  <Button
                    size="lg"
                    className="w-100"
                    title="Get FCC Pro"
                    onClick={() => redirectToSignupPopup("fcc_pro")}
                  >
                    Get FCC Pro
                  </Button>
                </Col>
              </Row>
            </Col>
            <Col
              lg={6}
              className="order-1 order-lg-2 d-flex flex-column justify-content-center d-none d-md-block"
            >
              <div style={{ height: "80px" }}></div>
              <Row>
                <Col
                  md={6}
                  className="d-flex align-items-stretch justify-content-center"
                >
                  <div className={styles.featureBox}>
                    <img
                      alt="Orange Phone Icon"
                      className="img-fluid d-block"
                      src="/react-assets/images/upgrades/Phone.png"
                    />
                    <h4>Private Number</h4>
                    <div>
                      Your conference number is exclusive and no access code is
                      needed
                    </div>
                  </div>
                </Col>
                <Col
                  md={6}
                  className="d-flex align-items-stretch justify-content-center"
                >
                  <div className={styles.featureBox}>
                    <img
                      alt="Orange Bell Icon"
                      className="img-fluid d-block"
                      src="/react-assets/images/upgrades/Bell.png"
                    />
                    <h4>Enhanced Notifications</h4>
                    <div>Get a text message when the first caller arrives</div>
                  </div>
                </Col>
                <Col
                  md={6}
                  className="d-flex align-items-stretch justify-content-center"
                >
                  <div className={styles.featureBox}>
                    <img
                      alt="Orange Arrow Up Icon"
                      className="img-fluid d-block"
                      src="/react-assets/images/upgrades/Arrow_Up.png"
                    />
                    <h4>Share & Collaborate</h4>
                    <div>
                      10 GB of Extra Storage for recordings and presentations
                    </div>
                  </div>
                </Col>
                <Col
                  md={6}
                  className="d-flex align-items-stretch justify-content-center"
                >
                  <div className={styles.featureBox}>
                    <img
                      alt="Orange Notes Icon"
                      className="img-fluid d-block"
                      src="/react-assets/images/upgrades/Notes.png"
                    />
                    <h4>Personalized Branding</h4>
                    <div>
                      Welcome participants with a custom greeting and set unique
                      hold music
                    </div>
                  </div>
                </Col>
              </Row>
            </Col>
          </Row>
        </section>
        <section className={styles.oneNumber}>
          <Row className="d-flex align-items-stretch">
            <Col lg={6} className="d-flex flex-column justify-content-center">
              <img
                alt="Conference Call"
                className="img-fluid d-block mx-auto"
                src="/images/upgrades/one_number.png"
              />
            </Col>
            <Col lg={6} className="d-flex flex-column justify-content-center">
              <p>
                <Badge bg="primary" className={styles.orangeBadge}>
                  Our Best Selling Feature
                </Badge>
              </p>
              <p>
                Starting at{" "}
                <big>{getLocalPrice(rates.one_number[period])}/mo</big>{" "}
                {period === "annual" && "billed annually"}
              </p>
              <h2>One Number</h2>
              <ul>
                <li>The Easiest Way for Your Callers to Connect</li>
                <li>No Extra Access Code</li>
                <li>Dial and Connect</li>
                <li>
                  Bonus: You get a text message when the first caller arrives
                </li>
              </ul>
              <Row>
                <Col lg={6}>
                  <Button
                    variant="orange"
                    size="lg"
                    className="w-100"
                    title="Get One Number"
                    onClick={() => redirectToSignupPopup("one_number")}
                  >
                    Get One Number
                  </Button>
                </Col>
              </Row>
            </Col>
          </Row>
        </section>
        <section className={styles.freeFeatures}>
          <Row className="d-flex align-items-stretch">
            <Col
              lg={5}
              md={6}
              className="order-2 order-md-1 d-flex flex-column justify-content-center"
            >
              <div className={styles.featuresBox}>
                <h4>Included for Free</h4>
                <ul>
                  <li>Domestic and International Calling numbers</li>
                  <li>Video Conferencing</li>
                  <li>Screen Sharing</li>
                  <li>1 GB of Cloud Storage</li>
                  <li>24/7 Customer Service</li>
                  <li>Desktop & Mobile App</li>
                  <li>No Scheduling Required</li>
                  <li>Up to 1000 Callers</li>
                  <li>No Meeting Time Limit</li>
                </ul>
                <Button
                  variant="orange"
                  size="lg"
                  className="w-100"
                  title="Create My Free Account"
                  onClick={() => redirectToSignupPopup("free_account_2")}
                >
                  Create My Free Account
                </Button>
              </div>
            </Col>
            <Col
              lg={7}
              md={6}
              className="order-1 order-md-2 d-flex flex-column justify-content-center"
            >
              <NoLimits />
            </Col>
          </Row>
        </section>
        <section className={styles.personalizedMeeting}>
          <h4>
            Personalize your meeting for just{" "}
            <span className="fcc-orange">
              {getLocalPrice(rates.conference_greeting)}/mo
            </span>
          </h4>
          <Row>
            <Col md={6}>
              <div className={styles.card}>
                <img
                  alt="Selfie Boy"
                  className="img-fluid d-block mx-auto"
                  src="/react-assets/images/upgrades/Selfie_Boy.png"
                />
                <div className={styles.cardBody}>
                  <h4>Custom Greeting</h4>
                  <p>
                    Welcome callers as they enter the conference line or online
                    meeting
                  </p>
                  <Row>
                    <Col lg={{ span: 8, offset: 2 }}>
                      <Button
                        variant="orange"
                        size="lg"
                        className="w-100"
                        title="Get Custom Greetings"
                        onClick={() =>
                          redirectToSignupPopup("conference_greeting")
                        }
                      >
                        Get Custom Greetings
                      </Button>
                    </Col>
                  </Row>
                </div>
              </div>
            </Col>
            <Col md={6}>
              <div className={styles.card}>
                <img
                  alt="Cassette"
                  className="img-fluid d-block mx-auto"
                  src="/react-assets/images/upgrades/Cassette.png"
                />
                <div className={styles.cardBody}>
                  <h4>Custom Hold Music</h4>
                  <p>
                    Create a waiting room for your guests with your own hold
                    tune.
                  </p>
                  <Row>
                    <Col lg={{ span: 8, offset: 2 }}>
                      <Button
                        variant="orange"
                        size="lg"
                        className="w-100"
                        title="Get Custom Hold Music"
                        onClick={() => redirectToSignupPopup("conference_moh")}
                      >
                        Get Custom Hold Music
                      </Button>
                    </Col>
                  </Row>
                </div>
              </div>
            </Col>
          </Row>
        </section>
      </Container>
      <section className={styles.extraStorage}>
        <Container>
          <h4>
            Record your meetings, upload presentations or share audio files
          </h4>
          <p>
            You can store, stream, and download your cloud recordings from the
            FreeConferenceCall cloud.
          </p>
          <Row className="mb-4">
            {rates.extra_storage.map((rate) => (
              <Col lg={2} md={4} xs={6} key={rate.id}>
                <div
                  className={`${styles.storageItem} ${
                    storageRateId === rate.id && styles.active
                  }`}
                  onClick={() => setStorageRate(rate.id)}
                >
                  <div className={styles.storagePrice}>
                    {getLocalPrice(rate)}
                  </div>
                  <div className={styles.storageAmount}>
                    {formatAmount(rate.amount)}
                  </div>
                </div>
              </Col>
            ))}
          </Row>
          <Row>
            <Col lg={{ span: 4, offset: 4 }}>
              <Button
                variant="orange"
                size="lg"
                className="w-100"
                title="Get More Storage"
                onClick={() => redirectToSignupPopup("extra_storage")}
              >
                Get More Storage
              </Button>
            </Col>
          </Row>
        </Container>
      </section>
    </div>
  );
}
