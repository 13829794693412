import { useEffect, useState } from "react";

import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Button from "react-bootstrap/Button";
import Navbar from "react-bootstrap/Navbar";
import Nav from "react-bootstrap/Nav";

import { Helmet } from "react-helmet-async";
import { useModal } from "../lib/ModalProvider";
import { useSession } from "../lib/SessionProvider.js";

import styles from "./Features.module.css";

export default function FeaturesPage() {
  const [activeSection, setActiveSection] = useState(null);
  const [isScrollingByClick, setIsScrollingByClick] = useState(false);

  const { openModal } = useModal();

  const { session } = useSession();

  const siteTag = "FCC_FP";
  const redirectUris = {
    free_account: "/profile",
    fcc_pro: {
      monthly: "/profile/upgrade/bundle?discount_group=complete_huddle",
    },
  };

  function openSignupModal(buttonTag = null) {
    let redirectUri =
      buttonTag === "fcc_pro"
        ? redirectUris["fcc_pro"]["monthly"]
        : redirectUris["free_account"];
    if (session.isLoggedIn) {
      window.location.assign(redirectUri);
    } else {
      const modalOptions = {
        siteTag: siteTag,
        redirectUri: redirectUri,
      };
      openModal("signup", modalOptions);
    }
  }

  useEffect(() => {
    const sections = document.querySelectorAll("section");

    const observer = new IntersectionObserver(
      (entries) => {
        let visibleSection = null;
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            visibleSection = entry.target.dataset.id;
          }
        });
        setActiveSection(visibleSection);
        if (!isScrollingByClick && visibleSection !== null) {
          window.history.replaceState(null, "", `#${visibleSection}`);
        }
      },
      {
        root: null, // Observes viewport
        rootMargin: "-50% 0px -50% 0px", // Ensures section is at least 50% visible before activating
        threshold: 0,
      }
    );

    sections.forEach((section) => observer.observe(section));

    return () => observer.disconnect();
  }, [isScrollingByClick]);

  const handleNavClick = (id) => {
    setIsScrollingByClick(true);

    document.getElementById(id)?.scrollIntoView({
      behavior: "smooth",
    });

    setTimeout(() => {
      setIsScrollingByClick(false);
    }, 1000);
  };

  function scrollToTop(event) {
    event.preventDefault();
    setIsScrollingByClick(true);

    window.scrollTo({ top: 0, behavior: "smooth" });
    window.history.replaceState(null, "", window.location.pathname);

    setTimeout(() => {
      setIsScrollingByClick(false);
    }, 1000);
  }

  const menuItems = {
    downloads: "Downloads",
    "before-the-meeting": "Before the Meeting",
    "during-the-meeting": "During the Meeting",
    "after-the-meeting": "After the Meeting",
    upgrades: "Upgrades",
    bundles: "Bundles",
  };

  return (
    <div className={styles.featuresPage}>
      <Helmet>
        <title>
          Our Free Conference Call Features | FreeConferenceCall.com
        </title>
        <meta
          name="description"
          content="We offer additional free conference call features designed to enhance team collaboration and ensure your virtual meetings are efficient and effective."
        />
        <meta name="robots" content="index, follow" />
        <link
          rel="canonical"
          href="https://www.freeconferencecall.com/features"
        />
        <meta property="og:title" content="About Us | FreeConferenceCall.com" />
        <meta property="og:type" content="company" />
        <meta
          property="og:url"
          content="https://www.freeconferencecall.com/features"
        />
        <meta
          property="og:image"
          content="https://www.freeconferencecall.com/images/fcc_logo_fb.png"
        />
        <meta iproperty="og:site_name" content="FreeConferenceCall.com" />
        <meta property="fb:admins" content="freeconferencecall" />
      </Helmet>
      <Container>
        <div className={styles.header}>
          <Row className="d-flex align-items-stretch">
            <Col
              lg={7}
              className="order-2 order-lg-1 d-flex flex-column justify-content-center p-3"
            >
              <h1>
                Check out the features that you can get with
                FreeConferenceCall.com
              </h1>
              <Row>
                <Col lg={6}>
                  <Button
                    variant="orange"
                    onClick={openSignupModal}
                    size="lg"
                    className="w-100"
                    title="Create Your Free Account"
                  >
                    Create Your Free Account
                  </Button>
                </Col>
              </Row>
            </Col>
            <Col
              lg={5}
              className="order-1 order-lg-2 d-flex flex-column justify-content-center p-3"
            >
              <img
                alt="Features Header"
                className="img-fluid d-block mx-auto"
                src="/react-assets/images/features/Features-Header.webp"
              />
            </Col>
          </Row>
        </div>
        <Row>
          <Col lg={3}>
            <div className={styles.affixMenu + " d-none d-lg-block"}>
              <Navbar>
                <Nav className="flex-column">
                  <Nav.Link className={styles.affixItem} onClick={scrollToTop}>
                    Back to Top
                  </Nav.Link>
                  {Object.entries(menuItems).map(([section, label]) => (
                    <Nav.Link
                      key={section}
                      href={`#${section}`}
                      className={
                        activeSection === section
                          ? styles.affixActiveItem
                          : styles.affixItem
                      }
                      onClick={(event) => {
                        event.preventDefault();
                        handleNavClick(section);
                      }}
                    >
                      {label}
                    </Nav.Link>
                  ))}
                </Nav>
              </Navbar>
            </div>
          </Col>
          <Col lg>
            <div className="jumptarget" id="downloads"></div>
            <section className={styles.downloads} data-id="downloads">
              <h2>Join Anywhere From Any Device</h2>
              <h3 className={styles.first}>
                Download the FreeConferenceCall.com app or join directly from
                your browser
              </h3>
              <Row className={styles.features}>
                <Col lg={4} className={styles.featureItem}>
                  <img
                    alt="Mobile"
                    src="/react-assets/images/features/Mobile-blue.png"
                  />
                  <span>Mobile</span>
                </Col>
                <Col lg={4} className={styles.featureItem}>
                  <img
                    alt="Desktop"
                    src="/react-assets/images/features/Desktop-blue.png"
                  />
                  <span>Desktop</span>
                </Col>
                <Col lg={4} className={styles.featureItem}>
                  <img
                    alt="Tablet"
                    src="/react-assets/images/features/Tablet-blue.png"
                  />
                  <span>Tablet</span>
                </Col>
              </Row>

              <Row className={styles.downloadsButton}>
                <Col lg={4} className="offset-lg-4">
                  <Button
                    href="/downloads"
                    className="w-100"
                    size="lg"
                    title="Download Now"
                  >
                    Download Now
                  </Button>
                </Col>
              </Row>

              <img
                alt="Calendar"
                className={styles.downloadsCalendar + " d-block mx-auto"}
                src="/react-assets/images/features/Calendar.png"
              />
              <h2>Run the Ultimate Online Meeting</h2>
              <h3>
                FreeConferenceCalls powerful virtual meeting features make it
                easy to plan, host, and collaborate for more productive
                meetings.
              </h3>
            </section>

            <div className="jumptarget" id="before-the-meeting"></div>
            <section
              className={styles.beforeTheMeeting}
              data-id="before-the-meeting"
            >
              <h2>Before the Meeting</h2>
              <h3>
                Get started with meeting tools that make scheduling and hosting
                meetings easy.
              </h3>
              <Row>
                <Col md={6} className="d-flex align-items-stretch">
                  <div className={styles.featureBox}>
                    <p>
                      <img
                        alt="Co-Hosts Icon"
                        src="/react-assets/images/features/Co-Hosts-orange.png"
                      />
                    </p>
                    <h4>
                      Share your unique meeting credentials with attendees and
                      co-hosts
                    </h4>
                    <p>
                      You can start scheduling unlimited meetings the day that
                      you create your account.
                    </p>
                  </div>
                </Col>
                <Col md={6} className="d-flex align-items-stretch">
                  <div className={styles.featureBox}>
                    <p>
                      <img
                        alt="Customize Icon"
                        src="/react-assets/images/features/Customize-orange.png"
                      />
                    </p>
                    <h4>
                      Customize your meeting lobby with documents, audio files,
                      and business contact information
                    </h4>
                    <p>
                      Branded lobbies help attendees know that they in the right
                      place at the right time.
                    </p>
                  </div>
                </Col>
                <Col md={6} className="d-flex align-items-stretch">
                  <div className={styles.featureBox}>
                    <p>
                      <img
                        alt="Upload Icon"
                        src="/react-assets/images/features/Upload-orange.png"
                      />
                    </p>
                    <h4>
                      Upload welcome messages, hold music or presentation files
                    </h4>
                    <p>
                      Our professional suite of presentation tools helps your
                      meeting stand out.
                    </p>
                  </div>
                </Col>
                <Col md={6} className="d-flex align-items-stretch">
                  <div className={styles.featureBox}>
                    <p>
                      <img
                        alt="Message Icon"
                        src="/react-assets/images/features/Message-orange.png"
                      />
                    </p>
                    <h4>Get a text message reminder</h4>
                    <p>
                      Set up your One Number to get a text message when the
                      first caller joins your conference line.
                    </p>
                  </div>
                </Col>
              </Row>
            </section>

            <div className="jumptarget" id="during-the-meeting"></div>
            <section
              className={styles.duringTheMeeting}
              data-id="during-the-meeting"
            >
              <h2>During the Meeting</h2>
              <h3>
                Share, collaborate and come up with new ideas with in real-time
                with these FreeConferenceCall.com features.
              </h3>
              <Row>
                <Col lg={4} md={6} className="d-flex align-items-stretch">
                  <div className={styles.featureBox}>
                    <p>
                      <img
                        alt="Screen Sharing Icon"
                        src="/react-assets/images/features/Screen-Sharing-orange.png"
                      />
                    </p>
                    <h4>Screen Sharing</h4>
                    <p>
                      Easily share your desktop, smartphone or tablet screen
                      with everyone. Present, collaborate and keep everyone on
                      the same page.
                    </p>
                  </div>
                </Col>
                <Col lg={4} md={6} className="d-flex align-items-stretch">
                  <div className={styles.featureBox}>
                    <p>
                      <img
                        alt="Drawing Tools Icon"
                        src="/react-assets/images/features/Drawing-Tools-orange.png"
                      />
                    </p>
                    <h4>Meeting Drawing Tools</h4>
                    <p>
                      Draw on your screen to collaborate, brainstorm or present
                      in real-time.
                    </p>
                  </div>
                </Col>
                <Col lg={4} md={6} className="d-flex align-items-stretch">
                  <div className={styles.featureBox}>
                    <p>
                      <img
                        alt="Mobile Meetings Icon"
                        src="/react-assets/images/features/Mobile-Meetings-orange.png"
                      />
                    </p>
                    <h4>Commuter Mode for Mobile Meetings</h4>
                    <p>
                      Call in with while on the go. Our mobile app lets you take
                      meeting with you.
                    </p>
                  </div>
                </Col>
                <Col md={6} className="d-flex align-items-stretch">
                  <div className={styles.featureBox}>
                    <p>
                      <img
                        alt="Custom Backgrounds Icon"
                        src="/react-assets/images/features/Custom-Backgrounds-orange.png"
                      />
                    </p>
                    <h4>Custom Backgrounds</h4>
                    <p>
                      Customize your webcam background and truly work from
                      “anywhere”.
                    </p>
                  </div>
                </Col>
                <Col md={6} className="d-flex align-items-stretch">
                  <div className={styles.featureBox}>
                    <p>
                      <img
                        alt="Presenter Control Icon"
                        src="/react-assets/images/features/Presenter-Control-orange.png"
                      />
                    </p>
                    <h4>Presenter Control</h4>
                    <p>
                      Give meeting guests presenter control so they can share
                      their screen and help run the meeting. You can even share
                      control of your keyboard or mouse.
                    </p>
                  </div>
                </Col>
              </Row>
            </section>

            <div className="jumptarget" id="after-the-meeting"></div>
            <section
              className={styles.afterTheMeeting}
              data-id="after-the-meeting"
            >
              <h2>After the Meeting</h2>
              <h3>Review the meeting for action items and key takeaways.</h3>
              <Row>
                <Col lg={4} md={6} className="d-flex align-items-stretch">
                  <div className={styles.featureBox}>
                    <p>
                      <img
                        alt="Cloud Recording Icon"
                        src="/react-assets/images/features/Cloud-Recording-orange.png"
                      />
                    </p>
                    <h4>Cloud Recording</h4>
                    <p>Record your meetings and share or revisit them later.</p>
                  </div>
                </Col>
                <Col lg={4} md={6} className="d-flex align-items-stretch">
                  <div className={styles.featureBox}>
                    <p>
                      <img
                        alt="Meeting Reports Icon"
                        src="/react-assets/images/features/Meeting-Reports-orange.png"
                      />
                    </p>
                    <h4>Meeting Reports</h4>
                    <p>
                      Collect contact information for each attendee alongside
                      stats on how they connected and for how long.
                    </p>
                  </div>
                </Col>
                <Col lg={4} md={6} className="d-flex align-items-stretch">
                  <div className={styles.featureBox}>
                    <p>
                      <img
                        alt="Meeting Keywords Icon"
                        src="/react-assets/images/features/Meeting-Keywords-orange.png"
                      />
                    </p>
                    <h4>Meeting Keywords</h4>
                    <p>
                      Easily share keyword transcripts of your recorded meetings
                      or look back for important notes and action items.
                    </p>
                  </div>
                </Col>
              </Row>
            </section>

            <div className="jumptarget" id="upgrades"></div>
            <section className={styles.upgrades} data-id="upgrades">
              <h2>Upgrades</h2>
              <div className={styles.upgradesBox}>
                <div className={styles.boxTag}>Our Best Selling Feature!</div>
                <Row>
                  <Col md={4} className={styles.boxImage}>
                    <img alt="Circle Phone Icon" src="/react-assets/images/features/Phone-blue-circle.png" />
                  </Col>
                  <Col md>
                    <div className={styles.boxContent}>
                      <h3>One Number</h3>
                      <div className={styles.boxText}>
                        One Number eliminates access codes connecting you and
                        your attendees in an instant. Simply dial a 10 digit
                        phone number and you’re ready to start your meeting.
                      </div>
                    </div>
                  </Col>
                </Row>
              </div>

              <Row>
                <Col md={6} className="d-flex align-items-stretch">
                  <div className={styles.featureBox}>
                    <p>
                      <img
                        alt="Custom Greeting Icon"
                        src="/react-assets/images/features/Custom-Greeting-orange.png"
                      />
                    </p>
                    <h4>Custom Greeting</h4>
                    <p>
                      Welcome callers with a message that’s specific to your
                      brand.
                    </p>
                  </div>
                </Col>
                <Col md={6} className="d-flex align-items-stretch">
                  <div className={styles.featureBox}>
                    <p>
                      <img
                        alt="Custom Hold Music Icon"
                        src="/react-assets/images/features/Custom-Hold-Music-orange.png"
                      />
                    </p>
                    <h4>Custom Hold Music</h4>
                    <p>
                      Say goodbye to elevator music. Upload and choose your own
                      hold music for a unique conference call experience.
                    </p>
                  </div>
                </Col>
                <Col md={6} className="d-flex align-items-stretch">
                  <div className={styles.featureBox}>
                    <p>
                      <img
                        alt="Toll-Free Audio Icon"
                        src="/react-assets/images/features/Toll-Free-Audio-orange.png"
                      />
                    </p>
                    <h4>Toll-Free Audio</h4>
                    <p>
                      Make a lasting impression with a trusted number that
                      demonstrates your commitment to customer satisfaction.
                    </p>
                  </div>
                </Col>
                <Col md={6} className="d-flex align-items-stretch">
                  <div className={styles.featureBox}>
                    <p>
                      <img
                        alt="Extra Storage Icon"
                        src="/react-assets/images/features/Extra-Storage-orange.png"
                      />
                    </p>
                    <h4>Extra Storage</h4>
                    <p>
                      Our extra cloud options give you the room you need to
                      grow, whether for personal use or business expansion.
                    </p>
                  </div>
                </Col>
              </Row>
            </section>

            <div className="jumptarget" id="bundles"></div>
            <section className={styles.bundles} data-id="bundles">
              <h2>Meetings Just Got Better, Bundle and Save with FCC Pro</h2>
              <Row>
                <Col md={6}>
                  <div className={styles.bundleBox}>
                    <img
                      className="img-fluid"
                      alt="Easy Icon"
                      src="/react-assets/images/features/Easy-image.jpg"
                    />
                    <h4>😌 Easy</h4>
                    <p>
                      No more access codes. Connect immediately with a private
                      meeting number.
                    </p>
                  </div>
                </Col>
                <Col md={6}>
                  <div className={styles.bundleBox}>
                    <img
                      className="img-fluid"
                      alt="Punctual Icon"
                      src="/react-assets/images/features/Punctual-image.jpg"
                    />
                    <h4>📬 Punctual</h4>
                    <p>
                      Receive a text message when the first person joins your
                      meeting.
                    </p>
                  </div>
                </Col>
                <Col md={6}>
                  <div className={styles.bundleBox}>
                    <img
                      className="img-fluid"
                      alt="Branding Icon"
                      src="/react-assets/images/features/Branding-image.jpg"
                    />
                    <h4>✨ Branding</h4>
                    <p>
                      Welcome meeting participants with a custom greeting and
                      hold tune.
                    </p>
                  </div>
                </Col>
                <Col md={6}>
                  <div className={styles.bundleBox}>
                    <img
                      className="img-fluid"
                      alt="Secure Storage Icon"
                      src="/react-assets/images/features/Secure-Storage-image.jpg"
                    />
                    <h4>📦 Secure Storage</h4>
                    <p>
                      Our extra cloud options give you the room you need to
                      grow, whether for personal use or business expansion.
                    </p>
                  </div>
                </Col>
              </Row>
              <Row>
                <Col md={4} className="offset-md-4">
                  <Button
                    variant="orange"
                    onClick={() => openSignupModal("fcc_pro")}
                    size="lg"
                    className="w-100"
                    title="Try FCC Pro For Free"
                  >
                    Try FCC Pro For Free
                  </Button>
                </Col>
              </Row>
            </section>
          </Col>
        </Row>
      </Container>
    </div>
  );
}
