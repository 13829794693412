import { useState } from "react";

import { Helmet } from "react-helmet-async";
import { useLoaderData } from "react-router-dom";

import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";

import { useSession } from "../lib/SessionProvider.js";

import styles from "./PWYC.module.css";
import NoLimits from "../components/NoLimits.js";

export default function PWYCPage() {
  const { session } = useSession();

  const data = useLoaderData();

  const country = data.countries.find(
    (country) => country.code === session.countryCode
  );
  const currencyCode = country ? country.currency_code : "USD";
  const localCurrency = data.currencies.find(
    (currency) => currency.code === currencyCode
  );
  const defaultCurrency = data.currencies.find(
    (currency) => currency.code === "USD"
  );

  let rates = {};
  Object.keys(data.rates.pwyc).forEach((key) => {
    rates[key] = getPwycRate(data.rates.pwyc[key]);
  });

  let step = 1;
  if (rates.ui_max.value > 100000) {
    step = 1000;
  } else if (rates.ui_max.value > 10000) {
    step = 100;
  }

  rates.ui_max.value = Math.floor(rates.ui_max.value / step) * step;
  rates.complete.value = Math.ceil(rates.complete.value / step) * step;

  Object.entries(rates).forEach(([key, rate]) => {
    rates[key].formattedPrice = formatPrice(rate.value, rate.currency);
  });

  function getPwycRate(rate) {
    let currency = localCurrency;
    const countryRate = rate.fees_recurrent.find(
      (countryRate) => countryRate.currency_code === currency.code
    );

    let value;
    if (countryRate) {
      value = countryRate.price;
    } else {
      value = rate.recurrent_fee;
      currency = defaultCurrency;
    }

    return {
      value: value,
      currency: currency,
    };
  }

  function formatPrice(price, currency) {
    return currency.format_pattern.replace("%s", price);
  }

  function redirectToLogin() {
    const slider = document.getElementById("slider");
    const redirectURI =
      "/profile/upgrade/pay_what_you_can?fee=" +
      slider.value +
      "&campaign_tag=FCC20_WEB_PWY_SLIDER";
    const signupURI =
      "/profile/signup/pay_what_you_can?fee=" +
      slider.value +
      "&campaign_tag=FCC20_WEB_PWY_SLIDER";

    if (session.isLoggedIn) {
      window.location.assign(redirectURI);
    } else {
      const loginURL =
        "/login-2?signup_redirect_uri=" +
        encodeURIComponent(signupURI) +
        "&redirect_uri=" +
        redirectURI;
      window.location.assign(loginURL);
    }
  }

  function scrollToTop(event) {
    event.preventDefault();
    window.scrollTo({ top: 0, behavior: "smooth" });
  }

  const [amount, setAmount] = useState(rates.complete.value);
  function updateAmount(event) {
    const newAmount = Number(event.target.value);
    setAmount(newAmount);

    const amountElm = document.getElementById("donationAmount");
    amountElm.innerText = formatPrice(newAmount, rates.complete.currency);

    let elms = document.querySelectorAll(`.${styles.featureBox}`);
    elms.forEach((elm) => {
      elm.classList.remove(styles.active);
    });

    let query = "";
    let smileElm = document.getElementById("smile");
    if (newAmount >= rates.elite.value) {
      if (newAmount === rates.ui_max.value) {
        smileElm.src = "/react-assets/images/pwyc/face_smile_max.png";
      } else {
        smileElm.src = "/react-assets/images/pwyc/face_smile_elite.png";
      }
      query = `.${styles.featureBox}`;
    } else if (newAmount >= rates.complete.value) {
      smileElm.src = "/react-assets/images/pwyc/face_smile_complete.png";
      query = `.${styles.featureBox}.complete`;
    } else {
      smileElm.src = "/react-assets/images/pwyc/face_smile_basic.png";
      query = `.${styles.featureBox}.basic`;
    }

    elms = document.querySelectorAll(query);
    elms.forEach((elm) => {
      elm.classList.add(styles.active);
    });
  }

  const ratio =
    ((amount - rates.ui_min.value) /
      (rates.ui_max.value - rates.ui_min.value)) *
    100;

  return (
    <div className={styles.pwycPage}>
      <Helmet>
        <title>Pay What You Can | FreeConferenceCall.com</title>
        <meta
          name="description"
          content="Free conference call download app options for Mac, Windows, Android, and iPhone. Download your FreeConferenceCall.com app today."
        />
        <link rel="canonical" href="https://www.freeconferencecall.com/pwyc" />
        <meta name="robots" content="index, follow" />
        <meta
          property="og:title"
          content="Pay What You Can | FreeConferenceCall.com"
        />
        <meta property="og:type" content="company" />
        <meta
          property="og:url"
          content="https://www.freeconferencecall.com/pwyc"
        />
        <meta
          property="og:image"
          content="https://www.freeconferencecall.com/images/fcc_logo_fb.png"
        />
        <meta iproperty="og:site_name" content="FreeConferenceCall.com" />
        <meta property="fb:admins" content="freeconferencecall" />
      </Helmet>
      <section className={styles.sellBox}>
        <Container>
          <div className="d-block d-lg-none">
            <h1 className="text-left">Pay What You Can</h1>
            <p className={styles.lead}>
              Pay what you think is fair and unlock premium features. Your
              contribution will help us provide under-served communities with
              access to free, world class communications.
            </p>
            <br />
          </div>
          <Row>
            <Col lg={4} md={7}>
              <div className={styles.sliderBox}>
                <img
                  id="smile"
                  alt="Face Smile"
                  className="img-fluid d-block mx-auto"
                  src="/react-assets/images/pwyc/face_smile_complete.png"
                />
                <div className={styles.sliderContainer}>
                  <Form.Range
                    min={rates.ui_min.value}
                    step={step}
                    max={rates.ui_max.value}
                    value={amount}
                    className={styles.slider}
                    id="slider"
                    style={{
                      background: `linear-gradient(to right, #f8921a 0%, #f8921a ${ratio}%, #e7e9ed ${ratio}%, #e7e9ed 100%)`,
                    }}
                    onChange={updateAmount}
                  />
                </div>
                <Row>
                  <Col lg={{ span: 6, offset: 3 }}>
                    <div id="donationAmount" className={styles.donationAmount}>
                      {rates.complete.formattedPrice}
                    </div>
                  </Col>
                </Row>
                <Button
                  size="lg"
                  className="w-100"
                  title="Contribute Monthly"
                  onClick={redirectToLogin}
                >
                  Contribute Monthly
                </Button>
              </div>
            </Col>
            <Col lg={8} md={5} className={styles.sliderInfo}>
              <div className="d-none d-lg-block">
                <h1 className="text-left">Pay What You Can</h1>
                <p className={styles.lead}>
                  Pay what you think is fair and unlock premium features. Your
                  contribution will help us provide under-served communities
                  with access to free, world class communications.
                </p>
                <br />
              </div>
              <div className={styles.features}>
                <Row>
                  <Col lg={4}>
                    <div
                      className={`${styles.featureBox} ${styles.active} basic complete`}
                    >
                      <img
                        alt="Unlocked Basil Icon"
                        src="/react-assets/images/pwyc/basil_unlock-outline.png"
                      />
                      <span className={styles.featureText}>
                        Virtual Backgrounds
                        <span className={styles.featureState}>Unlocked</span>
                      </span>
                    </div>
                  </Col>
                  <Col lg={4} className="d-none d-lg-flex">
                    <div
                      className={`${styles.featureBox} ${styles.active} complete`}
                    >
                      <img
                        alt="Unlocked Basil Icon"
                        src="/react-assets/images/pwyc/basil_unlock-outline.png"
                      />
                      <span className={styles.featureText}>
                        Branding
                        <span className={styles.featureState}>Removed</span>
                      </span>
                    </div>
                  </Col>
                  <Col lg={4} className="d-none d-lg-flex">
                    <div
                      className={`${styles.featureBox} ${styles.active} complete`}
                    >
                      <img
                        alt="Unlocked Basil Icon"
                        src="/react-assets/images/pwyc/basil_unlock-outline.png"
                      />
                      <span className={styles.featureText}>
                        Custom Greetings
                        <span className={styles.featureState}>Unlocked</span>
                      </span>
                    </div>
                  </Col>
                </Row>
                <Row className="d-none d-lg-flex">
                  <Col lg={4}>
                    <div
                      className={`${styles.featureBox} ${styles.active} basic complete`}
                    >
                      <img
                        alt="Unlocked Basil Icon"
                        src="/react-assets/images/pwyc/basil_unlock-outline.png"
                      />
                      <span className={styles.featureText}>
                        Blue Heart Badge
                        <span className={styles.featureState}>Unlocked</span>
                      </span>
                    </div>
                  </Col>
                  <Col lg={4}>
                    <div
                      className={`${styles.featureBox} ${styles.active} complete`}
                    >
                      <img
                        alt="Unlocked Basil Icon"
                        src="/react-assets/images/pwyc/basil_unlock-outline.png"
                      />
                      <span className={styles.featureText}>
                        11 GB of Storage
                        <span className={styles.featureState}>Unlocked</span>
                      </span>
                    </div>
                  </Col>
                  <Col lg={4}>
                    <div
                      className={`${styles.featureBox} ${styles.active} complete`}
                    >
                      <img
                        alt="Unlocked Basil Icon"
                        src="/react-assets/images/pwyc/basil_unlock-outline.png"
                      />
                      <span className={styles.featureText}>
                        Default Greeting
                        <span className={styles.featureState}>Removed</span>
                      </span>
                    </div>
                  </Col>
                </Row>
                <Row>
                  <Col lg={4}>
                    <div
                      className={`${styles.featureBox} ${styles.active} complete`}
                    >
                      <img
                        alt="Unlocked Basil Icon"
                        src="/react-assets/images/pwyc/basil_unlock-outline.png"
                      />
                      <span className={styles.featureText}>
                        <span className="d-none d-md-inline">One Number</span>
                        <span className="d-inline d-md-none">
                          One Number & More
                        </span>
                        <span className={styles.featureState}>Unlocked</span>
                      </span>
                    </div>
                  </Col>
                  <Col lg={4} className="d-none d-lg-flex">
                    <div
                      className={`${styles.featureBox} ${styles.active} complete`}
                    >
                      <img
                        alt="Unlocked Basil Icon"
                        src="/react-assets/images/pwyc/basil_unlock-outline.png"
                      />
                      <span className={styles.featureText}>
                        Custom Hold Music
                        <span className={styles.featureState}>Unlocked</span>
                      </span>
                    </div>
                  </Col>
                  <Col lg={4}>
                    <div className={styles.featureBox}>
                      <img
                        alt="Unlocked Basil Icon"
                        src="/react-assets/images/pwyc/basil_unlock-outline.png"
                      />
                      <span className={styles.featureText}>
                        <span className="d-inline d-md-none">
                          Best Features & Gratitude
                        </span>
                        <span className="d-none d-md-inline">
                          Good Feelings
                        </span>
                        <span className={styles.featureState}>Unlocked</span>
                      </span>
                    </div>
                  </Col>
                </Row>
              </div>
            </Col>
          </Row>
        </Container>
      </section>
      <Container>
        <section className={styles.freeFeatures}>
          <Row>
            <Col lg={4}>
              <h2 className={styles.large}>What We Offer for Free</h2>
            </Col>
            <Col lg={8}>
              <NoLimits />
            </Col>
          </Row>
        </section>
        <section className={styles.yourSupport}>
          <Row>
            <Col
              lg={6}
              className="d-flex flex-column justify-content-center order-2 order-lg-1"
            >
              <p className={styles.lead}>
                Show your support with a blue heart badge added for our most
                generous community members
              </p>
            </Col>
            <Col
              lg={6}
              className="d-flex flex-column justify-content-center order-1 order-lg-2"
            >
              <img
                alt="Blue Heart"
                className="img-fluid d-block mx-auto"
                src="/react-assets/images/pwyc/Blue_Heart.webp"
              />
            </Col>
          </Row>
        </section>
        <section className={styles.paidFeatures}>
          <Row>
            <Col lg={6} className="d-flex flex-column justify-content-center">
              <img
                alt="Paid Features"
                className="img-fluid d-block mx-auto"
                src="/react-assets/images/pwyc/Paid_Features.webp"
              />
            </Col>
            <Col lg={6} className="d-flex flex-column justify-content-center">
              <p className={styles.lead + " mb-5"}>
                Explore our paid features and professional plans to learn more
                about how to improve your meeting experience
              </p>
              <Row>
                <Col lg={6}>
                  <Button
                    href="/upgrades"
                    size="lg"
                    title="View Paid Features"
                    className="w-100"
                  >
                    View Paid Features
                  </Button>
                </Col>
              </Row>
            </Col>
          </Row>
        </section>
      </Container>
      <div className={styles.makeAContribution}>
        <Container>
          <Row>
            <Col lg={{ span: 8, offset: 2 }}>
              <div className={styles.feelsGood}>It feels good to give back</div>
            </Col>
            <Col lg={{ span: 4, offset: 4 }}>
              <Button
                className="w-100"
                size="lg"
                title="Make a Contribution"
                onClick={scrollToTop}
              >
                Make a Contribution
              </Button>
            </Col>
          </Row>
        </Container>
      </div>
    </div>
  );
}
