import Container from "react-bootstrap/Container";
import Table from "react-bootstrap/Table";

import styles from "./ComparePlans.module.css";

export default function Competition() {
  return (
    <section className={styles.competition}>
      <Container>
        <h2>FreeConferenceCall vs. the Competition</h2>
        <h3>
          We offer the ultimate solution for free users. With unlimited video
          conferencing, HD audio, and screen sharing—all at no cost— empowering
          teams to connect without limitations. Experience the freedom of
          collaboration without the price tag and elevate your online meetings
          today!
        </h3>
        <div className={styles.tableBox}>
          <Table responsive>
            <thead>
              <tr>
                <th></th>
                <th>
                  <h4 style={{ color: "var(--fcc-blue)" }}>
                    FreeConferenceCall
                  </h4>
                </th>
                <th>
                  <h4>GoToMeeting(Free)</h4>
                </th>
                <th>
                  <h4>Zoom(Basic)</h4>
                </th>
                <th>
                  <h4>Webex(Free)</h4>
                </th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <th style={{ color: "var(--fcc-pricing-gray)" }} colSpan="5">
                  Features
                </th>
              </tr>
              <tr>
                <th>Meeting Duration</th>
                <td>Unlimited</td>
                <td>40 min Limit</td>
                <td>40 min Limit</td>
                <td>40 min Limit</td>
              </tr>
              <tr className={styles.blueBackground}>
                <th>Participant Capacity</th>
                <td>1000</td>
                <td>3</td>
                <td>100</td>
                <td>100</td>
              </tr>
              <tr>
                <th>Recording</th>
                <td>1 GB</td>
                <td>
                  <img
                    alt="Gray Close Icon"
                    src="/react-assets/images/pricing/Close-gray.png"
                  />
                </td>
                <td>Local</td>
                <td>Local</td>
              </tr>
              <tr className={styles.blueBackground}>
                <th>Chat</th>
                <td>
                  <img
                    alt="Green Check Circle"
                    src="/react-assets/images/pricing/Check-Circle-green.png"
                  />
                </td>
                <td>
                  <img
                    alt="Green Check Circle"
                    src="/react-assets/images/pricing/Check-Circle-green.png"
                  />
                </td>
                <td>
                  <img
                    alt="Green Check Circle"
                    src="/react-assets/images/pricing/Check-Circle-green.png"
                  />
                </td>
                <td>
                  <img
                    alt="Green Check Circle"
                    src="/react-assets/images/pricing/Check-Circle-green.png"
                  />
                </td>
              </tr>
              <tr>
                <th>Audio</th>
                <td>
                  <img
                    alt="Green Check Circle"
                    src="/react-assets/images/pricing/Check-Circle-green.png"
                  />
                </td>
                <td>
                  <img
                    alt="Green Check Circle"
                    src="/react-assets/images/pricing/Check-Circle-green.png"
                  />
                </td>
                <td>
                  <img
                    alt="Green Check Circle"
                    src="/react-assets/images/pricing/Check-Circle-green.png"
                  />
                </td>
                <td>
                  <img
                    alt="Green Check Circle"
                    src="/react-assets/images/pricing/Check-Circle-green.png"
                  />
                </td>
              </tr>
              <tr className={styles.blueBackground}>
                <th>Video</th>
                <td>
                  <img
                    alt="Green Check Circle"
                    src="/react-assets/images/pricing/Check-Circle-green.png"
                  />
                </td>
                <td>
                  <img
                    alt="Green Check Circle"
                    src="/react-assets/images/pricing/Check-Circle-green.png"
                  />
                </td>
                <td>
                  <img
                    alt="Green Check Circle"
                    src="/react-assets/images/pricing/Check-Circle-green.png"
                  />
                </td>
                <td>
                  <img
                    alt="Green Check Circle"
                    src="/react-assets/images/pricing/Check-Circle-green.png"
                  />
                </td>
              </tr>
              <tr>
                <th>Screen Sharing</th>
                <td>
                  <img
                    alt="Green Check Circle"
                    src="/react-assets/images/pricing/Check-Circle-green.png"
                  />
                </td>
                <td>
                  <img
                    alt="Green Check Circle"
                    src="/react-assets/images/pricing/Check-Circle-green.png"
                  />
                </td>
                <td>
                  <img
                    alt="Green Check Circle"
                    src="/react-assets/images/pricing/Check-Circle-green.png"
                  />
                </td>
                <td>
                  <img
                    alt="Green Check Circle"
                    src="/react-assets/images/pricing/Check-Circle-green.png"
                  />
                </td>
              </tr>
              <tr className={styles.blueBackground}>
                <th>White Board</th>
                <td>
                  <img
                    alt="Green Check Circle"
                    src="/react-assets/images/pricing/Check-Circle-green.png"
                  />
                </td>
                <td>
                  <img
                    alt="Gray Close Icon"
                    src="/react-assets/images/pricing/Close-gray.png"
                  />
                </td>
                <td>
                  <img
                    alt="Green Check Circle"
                    src="/react-assets/images/pricing/Check-Circle-green.png"
                  />
                </td>
                <td>
                  <img
                    alt="Green Check Circle"
                    src="/react-assets/images/pricing/Check-Circle-green.png"
                  />
                </td>
              </tr>
              <tr>
                <th>Breakout Room</th>
                <td>
                  <img
                    alt="Green Check Circle"
                    src="/react-assets/images/pricing/Check-Circle-green.png"
                  />
                </td>
                <td>
                  <img
                    alt="Gray Close Icon"
                    src="/react-assets/images/pricing/Close-gray.png"
                  />
                </td>
                <td>
                  <img
                    alt="Green Check Circle"
                    src="/react-assets/images/pricing/Check-Circle-green.png"
                  />
                </td>
                <td>
                  <img
                    alt="Green Check Circle"
                    src="/react-assets/images/pricing/Check-Circle-green.png"
                  />
                </td>
              </tr>
            </tbody>
          </Table>
        </div>
      </Container>
    </section>
  );
}
