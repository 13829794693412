import { useEffect } from 'react';
import { loadScript } from "./helpers";

export default function LiveChat() {

  useEffect(function () {
    setTimeout(function () {
      window.__lc = window.__lc || {};
      window.__lc.license = 7519271;
      loadScript('https://cdn.livechatinc.com/tracking.js');
    }, 10000);
  });

  return null;
}
