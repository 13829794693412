import Tooltip from "react-bootstrap/Tooltip";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Table from "react-bootstrap/Table";
import Container from "react-bootstrap/Container";

import styles from "./ComparePlans.module.css";

export default function ComparePlans({ rates, period }) {
  return (
    <section className={styles.comparePlans}>
      <Container>
        <h2>Compare Plans</h2>
        <div className={styles.tableBox}>
          <Table responsive>
            <thead>
              <tr>
                <th></th>
                <th>
                  <h3>Free</h3>
                  <h4>$0/mo</h4>
                </th>
                <th>
                  <h3 style={{ color: "var(--fcc-blue)" }}>One Number</h3>
                  <h4 style={{ color: "var(--fcc-blue)" }}>
                    {rates.one_number[period].formatted_price}
                  </h4>
                </th>
                <th>
                  <h3 style={{ color: "var(--fcc-orange)" }}>Pro</h3>
                  <h4
                    style={{ color: "var(--fcc-orange)" }}
                    className="fcc-pro-price"
                  >
                    {rates.fcc_pro[period].formatted_price}
                  </h4>
                </th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <th style={{ color: "var(--fcc-pricing-gray)" }} colSpan="4">
                  Features
                </th>
              </tr>
              <tr>
                <th>Meeting Capacity</th>
                <td>1000</td>
                <td>1000</td>
                <td>1000</td>
              </tr>
              <tr className={styles.blueBackground}>
                <th>
                  One Number
                  <OverlayTrigger
                    placement="right"
                    overlay={
                      <Tooltip>
                        One Number is a private dial-in phone number where no
                        access code required.
                      </Tooltip>
                    }
                  >
                    <img
                      className={styles.info}
                      alt="Gray Info Icon"
                      src="/react-assets/images/pricing/Info-gray.png"
                    />
                  </OverlayTrigger>
                </th>
                <td>
                  <img
                    alt="Gray Close Icon"
                    src="/react-assets/images/pricing/Close-gray.png"
                  />
                </td>
                <td>
                  <img
                    alt="Green CheckcCircle Icon"
                    src="/react-assets/images/pricing/Check-Circle-green.png"
                  />
                </td>
                <td>
                  <img
                    alt="Green Check Circle Icon"
                    src="/react-assets/images/pricing/Check-Circle-green.png"
                  />
                </td>
              </tr>
              <tr>
                <th>
                  Meeting Reminder
                  <OverlayTrigger
                    placement="right"
                    overlay={
                      <Tooltip>
                        Registered phone numbers receive a sms notification when
                        the first caller connects to the meeting.
                      </Tooltip>
                    }
                  >
                    <img
                      className={styles.info}
                      alt="Gray Info Icon"
                      src="/react-assets/images/pricing/Info-gray.png"
                    />
                  </OverlayTrigger>
                </th>
                <td>
                  <img
                    alt="Gray Close Icon"
                    src="/react-assets/images/pricing/Close-gray.png"
                  />
                </td>
                <td>
                  <img
                    alt="Green CheckcCircle Icon"
                    src="/react-assets/images/pricing/Check-Circle-green.png"
                  />
                </td>
                <td>
                  <img
                    alt="Green CheckcCircle Icon"
                    src="/react-assets/images/pricing/Check-Circle-green.png"
                  />
                </td>
              </tr>
              <tr className={styles.blueBackground}>
                <th>
                  Automatic Host Recognition
                  <OverlayTrigger
                    placement="right"
                    overlay={
                      <Tooltip>
                        Registered Phone Number are immediately assigned the
                        role of ‘host’ no additional pin codes required.
                      </Tooltip>
                    }
                  >
                    <img
                      className={styles.info}
                      alt="Gray Info Icon"
                      src="/react-assets/images/pricing/Info-gray.png"
                    />
                  </OverlayTrigger>
                </th>
                <td>
                  <img
                    alt="Gray Close Icon"
                    src="/react-assets/images/pricing/Close-gray.png"
                  />
                </td>
                <td>
                  <img
                    alt="Green CheckcCircle Icon"
                    src="/react-assets/images/pricing/Check-Circle-green.png"
                  />
                </td>
                <td>
                  <img
                    alt="Green CheckcCircle Icon"
                    src="/react-assets/images/pricing/Check-Circle-green.png"
                  />
                </td>
              </tr>
              <tr>
                <th>
                  Save Conference Number as Contact
                  <OverlayTrigger
                    placement="right"
                    overlay={
                      <Tooltip>
                        Save the dial-in meeting number as a contact.
                      </Tooltip>
                    }
                  >
                    <img
                      className={styles.info}
                      alt="Gray Info Icon"
                      src="/react-assets/images/pricing/Info-gray.png"
                    />
                  </OverlayTrigger>
                </th>
                <td>
                  <img
                    alt="Green CheckcCircle Icon"
                    src="/react-assets/images/pricing/Check-Circle-green.png"
                  />
                </td>
                <td>
                  <img
                    alt="Green CheckcCircle Icon"
                    src="/react-assets/images/pricing/Check-Circle-green.png"
                  />
                </td>
                <td>
                  <img
                    alt="Green CheckcCircle Icon"
                    src="/react-assets/images/pricing/Check-Circle-green.png"
                  />
                </td>
              </tr>
              <tr className={styles.blueBackground}>
                <th>Custom Greeting</th>
                <td>
                  <img
                    alt="Gray Close Icon"
                    src="/react-assets/images/pricing/Close-gray.png"
                  />
                </td>
                <td>
                  <img
                    alt="Gray Close Icon"
                    src="/react-assets/images/pricing/Close-gray.png"
                  />
                </td>
                <td>
                  <img
                    alt="Green CheckcCircle Icon"
                    src="/react-assets/images/pricing/Check-Circle-green.png"
                  />
                </td>
              </tr>
              <tr>
                <th>Custom Hold Music</th>
                <td>
                  <img
                    alt="Gray Close Icon"
                    src="/react-assets/images/pricing/Close-gray.png"
                  />
                </td>
                <td>
                  <img
                    alt="Gray Close Icon"
                    src="/react-assets/images/pricing/Close-gray.png"
                  />
                </td>
                <td>
                  <img
                    alt="Green CheckcCircle Icon"
                    src="/react-assets/images/pricing/Check-Circle-green.png"
                  />
                </td>
              </tr>
              <tr className={styles.blueBackground}>
                <th>10 GB Extra Storage</th>
                <td>
                  <img
                    alt="Gray Close Icon"
                    src="/react-assets/images/pricing/Close-gray.png"
                  />
                </td>
                <td>
                  <img
                    alt="Gray Close Icon"
                    src="/react-assets/images/pricing/Close-gray.png"
                  />
                </td>
                <td>
                  <img
                    alt="Green CheckcCircle Icon"
                    src="/react-assets/images/pricing/Check-Circle-green.png"
                  />
                </td>
              </tr>
              <tr>
                <th className={styles.tableTitle} colSpan="4">
                  Meeting & Video Conferencing
                </th>
              </tr>
              <tr className={styles.blueBackground}>
                <th>
                  Dial-In Conference Number
                  <OverlayTrigger
                    placement="right"
                    overlay={
                      <Tooltip>
                        Receive a USA based conference dial in number for users.
                      </Tooltip>
                    }
                  >
                    <img
                      className={styles.info}
                      alt="Gray Info Icon"
                      src="/react-assets/images/pricing/Info-gray.png"
                    />
                  </OverlayTrigger>
                </th>
                <td>
                  <img
                    alt="Green CheckcCircle Icon"
                    src="/react-assets/images/pricing/Check-Circle-green.png"
                  />
                </td>
                <td>
                  <img
                    alt="Green CheckcCircle Icon"
                    src="/react-assets/images/pricing/Check-Circle-green.png"
                  />
                </td>
                <td>
                  <img
                    alt="Green CheckcCircle Icon"
                    src="/react-assets/images/pricing/Check-Circle-green.png"
                  />
                </td>
              </tr>
              <tr>
                <th>International Dial-In Numbers</th>
                <td>
                  <img
                    alt="Green CheckcCircle Icon"
                    src="/react-assets/images/pricing/Check-Circle-green.png"
                  />
                </td>
                <td>
                  <img
                    alt="Green CheckcCircle Icon"
                    src="/react-assets/images/pricing/Check-Circle-green.png"
                  />
                </td>
                <td>
                  <img
                    alt="Green CheckcCircle Icon"
                    src="/react-assets/images/pricing/Check-Circle-green.png"
                  />
                </td>
              </tr>
              <tr className={styles.blueBackground}>
                <th>HD Audio & Video</th>
                <td>
                  <img
                    alt="Green CheckcCircle Icon"
                    src="/react-assets/images/pricing/Check-Circle-green.png"
                  />
                </td>
                <td>
                  <img
                    alt="Green CheckcCircle Icon"
                    src="/react-assets/images/pricing/Check-Circle-green.png"
                  />
                </td>
                <td>
                  <img
                    alt="Green CheckcCircle Icon"
                    src="/react-assets/images/pricing/Check-Circle-green.png"
                  />
                </td>
              </tr>
              <tr>
                <th>Screen Sharing</th>
                <td>
                  <img
                    alt="Green CheckcCircle Icon"
                    src="/react-assets/images/pricing/Check-Circle-green.png"
                  />
                </td>
                <td>
                  <img
                    alt="Green CheckcCircle Icon"
                    src="/react-assets/images/pricing/Check-Circle-green.png"
                  />
                </td>
                <td>
                  <img
                    alt="Green CheckcCircle Icon"
                    src="/react-assets/images/pricing/Check-Circle-green.png"
                  />
                </td>
              </tr>
              <tr className={styles.blueBackground}>
                <th>
                  Chat
                  <OverlayTrigger
                    placement="right"
                    overlay={
                      <Tooltip>
                        Chat privately or with all participants to keep the
                        conference flowing.
                      </Tooltip>
                    }
                  >
                    <img
                      className={styles.info}
                      alt="Gray Info Icon"
                      src="/react-assets/images/pricing/Info-gray.png"
                    />
                  </OverlayTrigger>
                </th>
                <td>
                  <img
                    alt="Green CheckcCircle Icon"
                    src="/react-assets/images/pricing/Check-Circle-green.png"
                  />
                </td>
                <td>
                  <img
                    alt="Green CheckcCircle Icon"
                    src="/react-assets/images/pricing/Check-Circle-green.png"
                  />
                </td>
                <td>
                  <img
                    alt="Green CheckcCircle Icon"
                    src="/react-assets/images/pricing/Check-Circle-green.png"
                  />
                </td>
              </tr>
              <tr>
                <th>Reactions</th>
                <td>
                  <img
                    alt="Green CheckcCircle Icon"
                    src="/react-assets/images/pricing/Check-Circle-green.png"
                  />
                </td>
                <td>
                  <img
                    alt="Green CheckcCircle Icon"
                    src="/react-assets/images/pricing/Check-Circle-green.png"
                  />
                </td>
                <td>
                  <img
                    alt="Green CheckcCircle Icon"
                    src="/react-assets/images/pricing/Check-Circle-green.png"
                  />
                </td>
              </tr>
              <tr className={styles.blueBackground}>
                <th>White Board</th>
                <td>
                  <img
                    alt="Green CheckcCircle Icon"
                    src="/react-assets/images/pricing/Check-Circle-green.png"
                  />
                </td>
                <td>
                  <img
                    alt="Green CheckcCircle Icon"
                    src="/react-assets/images/pricing/Check-Circle-green.png"
                  />
                </td>
                <td>
                  <img
                    alt="Green CheckcCircle Icon"
                    src="/react-assets/images/pricing/Check-Circle-green.png"
                  />
                </td>
              </tr>
              <tr>
                <th>Document Sharing</th>
                <td>
                  <img
                    alt="Green CheckcCircle Icon"
                    src="/react-assets/images/pricing/Check-Circle-green.png"
                  />
                </td>
                <td>
                  <img
                    alt="Green CheckcCircle Icon"
                    src="/react-assets/images/pricing/Check-Circle-green.png"
                  />
                </td>
                <td>
                  <img
                    alt="Green CheckcCircle Icon"
                    src="/react-assets/images/pricing/Check-Circle-green.png"
                  />
                </td>
              </tr>
              <tr>
                <th className={styles.tableTitle} colSpan="4">
                  Meeting Management
                </th>
              </tr>
              <tr className={styles.blueBackground}>
                <th>Unlimited Meetings</th>
                <td>
                  <img
                    alt="Green CheckcCircle Icon"
                    src="/react-assets/images/pricing/Check-Circle-green.png"
                  />
                </td>
                <td>
                  <img
                    alt="Green CheckcCircle Icon"
                    src="/react-assets/images/pricing/Check-Circle-green.png"
                  />
                </td>
                <td>
                  <img
                    alt="Green CheckcCircle Icon"
                    src="/react-assets/images/pricing/Check-Circle-green.png"
                  />
                </td>
              </tr>
              <tr>
                <th>No Meeting Time Limits</th>
                <td>
                  <img
                    alt="Green CheckcCircle Icon"
                    src="/react-assets/images/pricing/Check-Circle-green.png"
                  />
                </td>
                <td>
                  <img
                    alt="Green CheckcCircle Icon"
                    src="/react-assets/images/pricing/Check-Circle-green.png"
                  />
                </td>
                <td>
                  <img
                    alt="Green CheckcCircle Icon"
                    src="/react-assets/images/pricing/Check-Circle-green.png"
                  />
                </td>
              </tr>
              <tr className={styles.blueBackground}>
                <th>Audio and Video Recording</th>
                <td>
                  <img
                    alt="Green CheckcCircle Icon"
                    src="/react-assets/images/pricing/Check-Circle-green.png"
                  />
                </td>
                <td>
                  <img
                    alt="Green CheckcCircle Icon"
                    src="/react-assets/images/pricing/Check-Circle-green.png"
                  />
                </td>
                <td>
                  <img
                    alt="Green CheckcCircle Icon"
                    src="/react-assets/images/pricing/Check-Circle-green.png"
                  />
                </td>
              </tr>
              <tr>
                <th>Cloud Storage (1GB)</th>
                <td>
                  <img
                    alt="Green CheckcCircle Icon"
                    src="/react-assets/images/pricing/Check-Circle-green.png"
                  />
                </td>
                <td>
                  <img
                    alt="Green CheckcCircle Icon"
                    src="/react-assets/images/pricing/Check-Circle-green.png"
                  />
                </td>
                <td>
                  <img
                    alt="Green CheckcCircle Icon"
                    src="/react-assets/images/pricing/Check-Circle-green.png"
                  />
                </td>
              </tr>
              <tr className={styles.blueBackground}>
                <th>Desktop and Mobile Apps</th>
                <td>
                  <img
                    alt="Green CheckcCircle Icon"
                    src="/react-assets/images/pricing/Check-Circle-green.png"
                  />
                </td>
                <td>
                  <img
                    alt="Green CheckcCircle Icon"
                    src="/react-assets/images/pricing/Check-Circle-green.png"
                  />
                </td>
                <td>
                  <img
                    alt="Green CheckcCircle Icon"
                    src="/react-assets/images/pricing/Check-Circle-green.png"
                  />
                </td>
              </tr>
              <tr>
                <th className={styles.tableTitle} colSpan="4">
                  Meeting Management
                </th>
              </tr>
              <tr className={styles.blueBackground}>
                <th>24/7 Live Customer Support</th>
                <td>
                  <img
                    alt="Green CheckcCircle Icon"
                    src="/react-assets/images/pricing/Check-Circle-green.png"
                  />
                </td>
                <td>
                  <img
                    alt="Green CheckcCircle Icon"
                    src="/react-assets/images/pricing/Check-Circle-green.png"
                  />
                </td>
                <td>
                  <img
                    alt="Green CheckcCircle Icon"
                    src="/react-assets/images/pricing/Check-Circle-green.png"
                  />
                </td>
              </tr>
            </tbody>
          </Table>
        </div>
      </Container>
    </section>
  );
}
