import { useState, useContext, createContext, lazy, Suspense } from "react";

const SignupModal = lazy(() => import("../components/SignupModal.js"));
const JoinModal = lazy(() => import("../components/JoinModal.js"));
const HostModal = lazy(() => import("../components/HostModal.js"));
const ForgotPasswordModal = lazy(() =>
  import("../components/ForgotPasswordModal.js")
);
const DownloadInfoModal = lazy(() =>
  import("../components/DownloadInfoModal.js")
);

const ModalContext = createContext();

export default function ModalProvider({ children }) {
  const [modalState, setModalState] = useState({
    activeModal: null,
    modalOptions: {},
  });

  const openModal = (modalName, options = {}) => {
    setModalState({ activeModal: modalName, modalOptions: options });
  };

  const closeModal = () =>
    setModalState({ activeModal: null, modalOptions: {} });

  return (
    <ModalContext.Provider value={{ ...modalState, openModal, closeModal }}>
      {children}
      <Suspense fallback={null}>
        {modalState.activeModal === "signup" && (
          <SignupModal
            show
            handleClose={closeModal}
            {...modalState.modalOptions}
          />
        )}
        {modalState.activeModal === "join" && (
          <JoinModal
            show
            handleClose={closeModal}
            {...modalState.modalOptions}
          />
        )}
        {modalState.activeModal === "host" && (
          <HostModal
            show
            handleClose={closeModal}
            {...modalState.modalOptions}
          />
        )}
        {modalState.activeModal === "forgot_password" && (
          <ForgotPasswordModal
            show
            handleClose={closeModal}
            {...modalState.modalOptions}
          />
        )}
        {modalState.activeModal === "download_info" && (
          <DownloadInfoModal
            show
            handleClose={closeModal}
            {...modalState.modalOptions}
          />
        )}
      </Suspense>
    </ModalContext.Provider>
  );
}

export const useModal = () => useContext(ModalContext);
