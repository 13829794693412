import { Helmet } from "react-helmet-async";

import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Button from "react-bootstrap/Button";

import IOSSellbox from "./downloads/IOSSellbox.js";
import AndroidSellbox from "./downloads/AndroidSellbox.js";
import WindowsSellbox from "./downloads/WindowsSellbox.js";

import { useModal } from "../lib/ModalProvider.js";
import { useSession } from "../lib/SessionProvider.js";

import { createDownloadIFrame, getOS } from "../lib/helpers";
import {
  AndroidIcon,
  CalendarIcon,
  IntegrationsIcon,
  OutlookCalendarIcon,
  WindowsIcon,
  AppleIcon,
} from "../lib/Icons";

import styles from "./Downloads.module.css";
import MacOSSellbox from "./downloads/MacOSSellbox.js";

export default function DownloadsPage() {
  const { session } = useSession();
  const { openModal } = useModal();

  const os = getOS();
  console.log("OS=", os);

  const createDownloadUrl = (platform) => {
    let url = `/download?shiftPressed=true&platform=${platform}&no_conference=true`;
    if (session.isLoggedIn) {
      url += "subscription_id=" + session.subscription.subscription_id;
    }
    return url;
  };

  const handleDownload = (platform) => {
    console.log("platform=", platform);
    if (platform === "outlook") {
      window.location.href = "/nc/download?plugin=exe";
    } else if (platform === "outlook_msi") {
      window.location.href = "/nc/download?plugin=msi";
    } else if (platform === "win" || platform === "mac") {
      const downloadUrl = createDownloadUrl(platform);
      openModal("download_info");
      console.log("downloadUrl=", downloadUrl);
      createDownloadIFrame(downloadUrl);
    } else if (platform === "win_msi") {
      createDownloadIFrame("/download?localization=en&platform=msi");
    }
  };

  return (
    <div className={styles.downloadsPage}>
      <Helmet>
        <title>FreeConferenceCall Downloads | FreeConferenceCall.com</title>
        <meta
          name="description"
          content="Free conference call download app options for Mac, Windows, Android, and iPhone. Download your FreeConferenceCall.com app today."
        />
        <link
          rel="canonical"
          href="https://www.freeconferencecall.com/downloads"
        />
        <meta name="robots" content="index, follow" />
        <meta
          property="og:title"
          content="FreeConferenceCall Downloads | FreeConferenceCall.com"
        />
        <meta property="og:type" content="company" />
        <meta
          property="og:url"
          content="https://www.freeconferencecall.com/downloads"
        />
        <meta
          property="og:image"
          content="https://www.freeconferencecall.com/images/fcc_logo_fb.png"
        />
        <meta iproperty="og:site_name" content="FreeConferenceCall.com" />
        <meta property="fb:admins" content="freeconferencecall" />
      </Helmet>
      <Container>
        <h1>FreeConferenceCall Downloads</h1>
        {os === "macOS" && <MacOSSellbox onDownload={handleDownload} />}
        {os === "Windows" && <WindowsSellbox onDownload={handleDownload} />}
        {os === "Android" && <AndroidSellbox />}
        {os === "iOS" && <IOSSellbox />}
        <hr />
        <section>
          <h2 className="text-center">More download options</h2>
          <Row>
            {os !== "macOS" && (
              <Col lg={4} className="d-flex align-items-stretch">
                <div className={styles.featureBox}>
                  <AppleIcon />
                  <h3>Download Mac App</h3>
                  <p className={styles.boxAbstract}>
                    Host or join an online meeting with video and screen sharing
                    on the desktop app.
                  </p>
                  <p>
                    <a
                      href="/support#system-req-2"
                      title="Mac System Requirements"
                    >
                      Mac System Requirements
                    </a>
                  </p>
                  <p>
                    <Button
                      title="Download for macOS"
                      onClick={() => handleDownload("mac")}
                    >
                      Download for macOS
                    </Button>
                  </p>
                </div>
              </Col>
            )}
            {os !== "Windows" && (
              <Col lg={4} className="d-flex align-items-stretch">
                <div className={styles.featureBox}>
                  <WindowsIcon />
                  <h3>Download Windows App</h3>
                  <p className={styles.boxAbstract}>
                    Host or join an online meeting with video and screen sharing
                    on the desktop app.
                  </p>
                  <p>
                    <a
                      href="/support#system-req-2"
                      title="Windows System Requirements"
                    >
                      Windows System Requirements
                    </a>
                  </p>
                  <p>
                    <Button
                      title="Download for Windows"
                      onClick={() => handleDownload("win")}
                    >
                      Download for Windows
                    </Button>
                  </p>
                  <p>
                    <Button
                      variant="link"
                      title="Download .MSI version"
                      style={{ padding: "0" }}
                      onClick={() => handleDownload("win_msi")}
                    >
                      Download .MSI version
                    </Button>
                  </p>
                </div>
              </Col>
            )}
            {os !== "Android" && (
              <Col lg={4} className="d-flex align-items-stretch">
                <div className={styles.featureBox}>
                  <AndroidIcon />
                  <h3>Download Android App</h3>
                  <p className={styles.boxAbstract}>
                    Connect with an Android™ device for instant audio, screen
                    sharing and video conferencing — taking mobile online
                    meetings to a whole new level.
                  </p>
                  <p>
                    <a href="/apps#android-app" title="Android App Features">
                      Android App Features
                    </a>
                  </p>
                  <p>
                    <a
                      target="_blank"
                      rel="noreferrer"
                      title="Download the Mobile App"
                      href="https://play.google.com/store/apps/details?id=com.freeconferencecall.fccmeetingclient"
                    >
                      <img
                        alt="Apple App Store"
                        src="/react-assets/images/homepage/Google_Play_App.png"
                      />
                    </a>
                  </p>
                </div>
              </Col>
            )}
            {os !== "iOS" && (
              <Col lg={4} className="d-flex align-items-stretch">
                <div className={styles.featureBox}>
                  <AppleIcon />
                  <h3>Download iOS App</h3>
                  <p className={styles.boxAbstract}>
                    Host and manage conference calls from the convenience of
                    your iPhone®, iPad® or iPad Pro®, or join in on a screen
                    sharing and video conferencing session.
                  </p>
                  <p>
                    <a href="/apps#iphone-app" title="iOS App Features">
                      iOS App Features
                    </a>
                  </p>
                  <p>
                    <a
                      target="_blank"
                      rel="noreferrer"
                      title="Download the Mobile App"
                      href="https://apps.apple.com/us/app/free-conference-call/id1076834814"
                    >
                      <img
                        alt="Apple App Store"
                        src="/react-assets/images/homepage/Apple_App_Store.png"
                      />
                    </a>
                  </p>
                </div>
              </Col>
            )}
          </Row>
        </section>
        <hr />
        <section>
          <h2 className="text-center">Supported Integrations</h2>
          <Row>
            <Col lg={4} className="d-flex align-items-stretch">
              <div className={styles.featureBox}>
                <CalendarIcon />
                <h3>Google Calendar</h3>
                <p className={styles.boxAbstract}>
                  Easily schedule, start and track your conference calls by
                  using the integrated Google Calendar™.
                </p>
                <p>
                  <a
                    href="/google-calendar-extension-instructions"
                    title="Google Calendar™ Instructions"
                  >
                    Google Calendar™ Instructions
                  </a>
                </p>
                <p>
                  <Button
                    title="Download Google Calendar Extension"
                    href="/desktopApp/app/plugin/fcc/freeconferencecallcom_scheduler_qa-0.4.0.0-fx.xpi"
                  >
                    Download Extension
                  </Button>
                </p>
              </div>
            </Col>
            <Col lg={4} className="d-flex align-items-stretch">
              <div className={styles.featureBox}>
                <OutlookCalendarIcon />
                <h3>Outlook Calendar</h3>
                <p className={styles.boxAbstract}>
                  Get the integrated Outlook® plug-in for Windows to schedule a
                  meeting and invite participants.
                </p>
                <p>
                  <a
                    href="/outlook-plugin-instructions"
                    title="Outlook Plug-in Instructions"
                  >
                    Outlook Plug-in Instructions
                  </a>
                </p>
                <p>
                  <Button
                    title="Download Outlook Plug-in"
                    onClick={() => handleDownload("outlook")}
                  >
                    Download Outlook Plug-in
                  </Button>
                </p>
                <p>
                  <Button
                    variant="link"
                    title="Download .MSI version"
                    style={{ padding: "0" }}
                    onClick={() => handleDownload("outlook_msi")}
                  >
                    Download .MSI version
                  </Button>
                </p>
              </div>
            </Col>
            <Col lg={4} className="d-flex align-items-stretch">
              <div className={styles.featureBox}>
                <IntegrationsIcon />
                <h3>Integrations</h3>
                <p className={styles.boxAbstract}>
                  Want to integrate FreeConferenceCall.com with other apps you
                  love? Learn about integrations.
                </p>
                <p>
                  <a
                    href="/support#integrations"
                    title="Integration Instructions"
                  >
                    Integration Instructions
                  </a>
                </p>
                <p>
                  <Button title="Go to App Integrations" href="/integrations">
                    Go to App Integrations
                  </Button>
                </p>
              </div>
            </Col>
          </Row>
        </section>
      </Container>
    </div>
  );
}
