import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

import { AndroidIcon, WindowsIcon, AppleIcon } from "../../lib/Icons";

import styles from "../Downloads.module.css";

export default function AndroidSellbox() {
  return (
    <section className={styles.sellBox}>
      <Row>
        <Col lg={7} className="order-2 order-lg-1">
          <AndroidIcon />
          <h2>Download Android App</h2>
          <p className={styles.boxAbstract}>
            Connect with an Android™ device for instant audio, screen sharing
            and video conferencing — taking mobile online meetings to a whole
            new level.
          </p>
          <p className={styles.boxImageIcon}>
            <a
              target="_blank"
              rel="noreferrer"
              title="Download the Mobile App"
              href="https://play.google.com/store/apps/details?id=com.freeconferencecall.fccmeetingclient"
            >
              <img
                alt="Google Play Store"
                src="/react-assets/images/homepage/Google_Play_App.png"
              />
            </a>
          </p>
          <p className={styles.lead + " fcc-blue"}>Also available on:</p>
          <Row>
            <Col className="text-center">
              <WindowsIcon />
              <p className={styles.lead}>Windows</p>
            </Col>
            <Col className="text-center">
              <AppleIcon />
              <p className={styles.lead}>macOS®</p>
            </Col>
          </Row>
        </Col>
        <Col lg={5} className="order-1 order-lg-2">
          <img
            alt="Android Phone"
            className={styles.boxImage + " img-fluid d-block mx-auto"}
            src="/react-assets/images/downloads/Android_Sellbox.jpg"
          />
        </Col>
      </Row>
    </section>
  );
}
