import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Button from "react-bootstrap/esm/Button";

import { WindowsIcon } from "../../lib/Icons";

import styles from "../Downloads.module.css";

export default function WindowsSellbox({ onDownload }) {
  return (
    <section className={styles.sellBox}>
      <Row>
        <Col lg={7} className="order-2 order-lg-1">
          <WindowsIcon />
          <h2>Download Windows App</h2>
          <p className={styles.boxAbstract}>
            Host or join an online meeting with video and screen sharing on the
            desktop app.
          </p>
          <p className={styles.lead + " fcc-blue"}>
            Minimal operating system requirements:
          </p>
          <ul className={styles.boxAbstract}>
            <li>Windows® 7</li>
            <li>
              Bandwidth: 100Kb/s (HD Audio), 400Kb/s (screen sharing), 1.1Mb/s
              (video)
            </li>
            <li>
              Desktop: Chrome™ 60 and newer or Chromebook or Firefox® 78 and
              newer, or Edge 79 and newer, or Safari® 11.1 and newer.
            </li>
          </ul>
          <p>
            <Button
              size="lg"
              onClick={() => onDownload("win")}
              title="Download for Windows"
            >
              Download for Windows
            </Button>
          </p>
          <p>
            <Button
              variant="link"
              title="Download .MSI version"
              style={{ padding: "0" }}
              onClick={() => onDownload("win_msi")}
            >
              Download .MSI version
            </Button>
          </p>
        </Col>
        <Col lg={5} className="order-1 order-lg-2">
          <img
            alt="Laptop with Screen Sharing App"
            className={styles.boxImage + " img-fluid d-block mx-auto"}
            src="/react-assets/images/downloads/Downloads_Sellbox.jpg"
          />
        </Col>
      </Row>
    </section>
  );
}
