import { useEffect } from "react";

import { createBrowserRouter, RouterProvider } from "react-router-dom";
import HomePage from "./pages/Home";
import PricingPage from "./pages/Pricing";
import RootLayout from "./pages/Root";
// import ErrorPage from "./pages/Error";
import AboutusPage from "./pages/Aboutus";
import FeaturesPage from "./pages/Features";
import YourImpactPage from "./pages/YourImpact";
import DownloadsPage from "./pages/Downloads";
import PWYCPage from "./pages/PWYC";
import UpgradesPage from "./pages/Upgrades";

import SessionProvider from "./lib/SessionProvider";
import ModalProvider from "./lib/ModalProvider";
import FlashMessageProvider from "./lib/FlashMessageProvider";
import { HelmetProvider } from "react-helmet-async";
import TrackingScripts from "./lib/TrackingScripts";
import LiveChat from "./lib/LiveChat";
import { loadRates, loadData } from "./lib/helpers";

const RedirectTo404 = () => {
  useEffect(() => {
    window.location.replace("/page-not-found");
  }, []);

  return null;
};

const router = createBrowserRouter([
  {
    path: "/",
    element: <RootLayout />,
    // errorElement: <ErrorPage />,
    errorElement: <RedirectTo404 />,
    children: [
      { path: "/", element: <HomePage /> },
      { path: "/downloads", element: <DownloadsPage /> },
      { path: "/pwyc", element: <PWYCPage />, loader: loadData },
      { path: "/upgrades", element: <UpgradesPage />, loader: loadData },
      { path: "/aboutus", element: <AboutusPage /> },
      { path: "/features", element: <FeaturesPage /> },
      { path: "/pricing", element: <PricingPage />, loader: loadRates },
      {
        path: "/how-people-use-free-conference-call",
        element: <YourImpactPage />,
      },
    ],
  },
]);

export default function App() {
  return (
    <SessionProvider>
      <FlashMessageProvider>
        <ModalProvider>
          <HelmetProvider>
            <TrackingScripts />
            <LiveChat />
            <RouterProvider router={router} />
          </HelmetProvider>
        </ModalProvider>
      </FlashMessageProvider>
    </SessionProvider>
  );
}
