import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

import styles from "./NoLimits.module.css";

export default function NoLimits() {
  return (
    <div className={styles.noLimits}>
      <Row>
        <Col md={2} xs={4}>
          <img
            alt="Clock Icon"
            className="img-fluid d-block"
            src="/react-assets/images/Clock_Icon.png"
          />
        </Col>
        <Col md={10} xs={8}>
          <h2>No Meeting Time Limits</h2>
        </Col>
      </Row>
      <hr />
      <Row>
        <Col xs={6}>
          <p>
            <img
              alt="Krisp Logo"
              className="img-fluid d-block"
              src="/react-assets/images/Krisp_Logo.png"
            />
          </p>
          <p>
            <big>
              <strong>AI Powered Noise Cancellation</strong>
            </big>
          </p>
        </Col>
        <Col xs={6}>
          <p>
            <img
              alt="Basil Dialpad Logo"
              className="img-fluid d-block"
              src="/react-assets/images/Basil_Dialpad.png"
            />
          </p>
          <p>
            <strong>
              Telephone dial-in to meetings with your free conference number
            </strong>
          </p>
        </Col>
      </Row>
    </div>
  );
}
